import React, { useState } from 'react'
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import { DateTime } from 'luxon'
import Logo from '../../icons/logo-cutilei-white.png'
import ReactTooltip from 'react-tooltip'

import {
  Container,
  Row,
  BlockInfo,
  Details,
  DetailColumn,
  Image,
  Picture,
  InfoText,
  SubTitle,
  Light
} from './styles'

function Schedule ({
  data: schedule,
  position,
  numColumns,
  timeWindows,
  timeInterval,
  schedules,
  onClick: handleClick
}) {
  const firstName = schedule.professional.name.split(' ')[0]
  const scheduleDateTime = DateTime.fromFormat (`${schedule.date} ${schedule.time}`, 'yyyy-MM-dd H:mm')
  const isReservation = schedule.validated === undefined
  const isCompleted = DateTime.now () >= scheduleDateTime?.plus ({minutes: schedule.max_delay + 1})
  const isInProgress = !isCompleted && DateTime.now () > scheduleDateTime.minus ({minutes: 20})
  const isValidated = schedule.validated
  const isMissed = (
    (schedule.cutilei_schedule && (isCompleted && !isValidated))
    || (!schedule.cutilei_schedule && schedule.status === 'missed')
  )
  const lastSchedule = schedule.member?.schedules[0] ?? schedule.customer?.schedules[0]
  const totalBusinessSchedules = (
    schedule.member?.total_business_schedules ?? schedule.customer?.total_business_schedules
  )
  const [showDetails, setShowDetails] = useState (false)
  const {attributes, listeners, setNodeRef, transform, isDragging} = useDraggable ({
    id: schedule.id,
    disabled: schedule.cutilei_schedule,
    data: {
      type: isReservation ? 'reservation' : 'schedule',
      schedule
    }
  })

  const getPeriod = (reservation) => {
    const period = DateTime.fromISO (reservation.end_date)
      .diff (DateTime.fromISO (reservation.start_date), ['days', 'months']).toObject ()

    if (!period.months) return period.days === 0 || period.days === 7 || period.days === 15
      ? period.days > 0 ? `${period.days} dias` : '1 dia'
      : 'Personalizado'
    else if (period.months >= 5000) return 'Indeterminado'
    else return `${period.months} mês`
  }

  const duration = schedule.duration > timeInterval && isMissed
    ? timeInterval
    : schedule.duration + (schedule.duration % timeInterval)

  const endTime = isReservation
    ? schedule.end_time
    : DateTime.fromFormat (schedule.time, 'H:mm').plus ({
      minutes: duration
    }).toFormat ('H:mm')

  return (
    <>
      <Container
        ref={setNodeRef}
        onClick={handleClick}
        position={position}
        isDragging={isDragging}
        numColumns={numColumns}
        timewindows={timeWindows}
        timeInterval={timeInterval}
        schedule={schedule}
        schedules={schedules}
        starttime={isReservation ? schedule.start_time : schedule.time}
        endtime={endTime}
        isInProgress={isInProgress}
        isCompleted={isCompleted}
        isValidated={isValidated}
        isReservation={isReservation}
        cutileiSchedule={schedule.cutilei_schedule}
        status={schedule.status}
        style={{transform: CSS.Translate.toString (transform)}}
        onMouseEnter={() => setShowDetails (true)}
        onMouseLeave={() => setShowDetails (false)}
        data-for={schedule.id}
        data-tip
        {...listeners}
        {...attributes}
      >
        {schedule.customer &&
          <SubTitle>{schedule.customer.name.split (' ')[0]}</SubTitle>
        }
        {schedule.cutilei_schedule && (
          <BlockInfo>
            <Image src={Logo} alt='Agendamento Cutilei'/>
            <SubTitle style={{marginRight: schedule.cutilei_schedule ? '8%' : 0}}>
              {schedule.member?.name.split (' ')[0]}
            </SubTitle>
          </BlockInfo>
        )}
        {schedule.description &&
          <SubTitle>{schedule.description}</SubTitle>
        }
      </Container>
      {schedule && showDetails && !isDragging && (
        <ReactTooltip
          id={schedule.id}
          effect='solid'
          place='bottom' 
          backgroundColor='#252525'
          tooltipRadius='10'
          padding='10px'  
        >
          {isReservation ? (
            <Details>
              <Row>
                <InfoText>Profissional:</InfoText>
                <SubTitle>{schedule.professional?.nickname || firstName}</SubTitle>
              </Row>
              <Row>
                <InfoText>Descrição:</InfoText>
                <SubTitle>{schedule.description}</SubTitle>
              </Row>
              <Row>
                <InfoText>Data de início:</InfoText>
                <SubTitle>
                  {DateTime.fromISO (schedule.start_date).toFormat ('dd/MM/yyyy')}
                </SubTitle>
              </Row>
              <Row>
                <InfoText>Período:</InfoText>
                <SubTitle>{getPeriod (schedule)}</SubTitle>
              </Row>
              <Row>
                <InfoText>Horário de início:</InfoText>
                <SubTitle>{schedule.start_time}</SubTitle>
              </Row>
              <Row>
                <InfoText>Horário final:</InfoText>
                <SubTitle>{schedule.end_time}</SubTitle>
              </Row>
            </Details>
          ) : (
            <Details cutileiSchedule={schedule.cutilei_schedule}>
              <Row style={{margin: 0, justifyContent: 'space-between'}}>
                <DetailColumn>
                  <Row>
                    <InfoText>Cliente:</InfoText>
                    <SubTitle>{schedule.member?.name || schedule.customer?.name}</SubTitle>
                  </Row>
                  <Row>
                    <InfoText>Profissional:</InfoText>
                    <SubTitle>{schedule.professional?.nickname || firstName}</SubTitle>
                  </Row>
                  <Row>
                    <InfoText>Serviço:</InfoText>
                    <SubTitle>{schedule.service?.name}</SubTitle>
                  </Row>
                  <Row>
                    <InfoText>Data:</InfoText>
                    <SubTitle>
                      {DateTime.fromISO (schedule.date).toFormat ('dd/MM/yyyy')}
                    </SubTitle>
                  </Row>
                  <Row>
                    <InfoText>Horário:</InfoText>
                    <SubTitle>{schedule.time}</SubTitle>
                  </Row>
                </DetailColumn>
                <DetailColumn>
                  {schedule.member && <Picture src={schedule.member.profile_picture}/>}
                </DetailColumn>
              </Row>
              {schedule.first ? (
                <Row>
                  <SubTitle>Primeiro agendamento do cliente!</SubTitle>
                </Row>
              ) : totalBusinessSchedules > 0 && (
                <>
                  <Row>
                    <InfoText>Agendamentos realizados:</InfoText>
                    <SubTitle>{totalBusinessSchedules}</SubTitle>
                  </Row>
                  <Row>
                    <InfoText>Última visita:</InfoText>
                    <SubTitle>
                      {DateTime.fromISO (
                        `${lastSchedule?.date}T${lastSchedule?.time}`
                      ).toFormat ('dd/MM/yyyy - H:mm')}
                    </SubTitle>
                  </Row>
                </>
              )}
              {schedule.cutilei_schedule && (
                <Row style={{margin: 0, justifyContent: 'flex-end'}}>
                  <Image src={Logo} alt='Agendamento Cutilei'/>
                  <Light
                    isInProgress={isInProgress}
                    isCompleted={isCompleted}
                    isValidated={isValidated}
                    isReservation={isReservation}
                    cutileiSchedule={schedule.cutilei_schedule}
                    status={schedule.status}
                  />
                </Row>
              )}
            </Details>
          )}
        </ReactTooltip>
      )}
    </>
  )
}

export default Schedule
