import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import AlertDialog from '../../../components/AlertDialog'
import PasswordInput from '../../../components/Inputs/PasswordInput'
import { Formik } from 'formik'
import passwordValidator from '../../../validators/passwordValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'

import {
  Container,
  Form,
  SubTitle,
  Row,
  FormField,
  WarningText,
  Button
} from '../styles'

function ChangePassword ({ history }) {
  const [showOkDialog, setShowOkDialog] = useState (false)
  const user = AuthService.getUser ()
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = AuthService.getBusinessId ()

  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      <Formik
        validationSchema={passwordValidator}
        initialValues={{
          'password': '',
          'password_confirmation': ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          const url = isBusiness
            ? `/businesses/${businessId}`
            : `/businesses/${businessId}/professionals/${user.id}`

          try {
            await cutileiApi.put (url, {password: values.password}, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            })

            setSubmitting (false)
            toggleOkDialog ()
          } catch (error) {
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit} style={{width: 480}}>
            <SubTitle>Alterar senha</SubTitle>
            <Row>
              <FormField style={{margin: 0}}>
                <PasswordInput
                  placeholder='Nova senha'
                  value={values.password}
                  onChange={handleChange ('password')}
                  onBlur={handleBlur ('password')}
                />
                {touched.password && errors.password && (
                  <WarningText>
                    {errors.password}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField style={{margin: 0}}>
                <PasswordInput
                  placeholder='Confirmar senha'
                  value={values.password_confirmation}
                  onChange={handleChange ('password_confirmation')}
                  onBlur={handleBlur ('password_confirmation')}
                />
                {touched.password_confirmation && errors.password_confirmation && (
                  <WarningText>
                    {errors.password_confirmation}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <AlertDialog
              visible={showOkDialog}
              title='Sucesso'
              message='Senha alterada!'
              confirmText='Ok'
              onConfirm={() => history.replace ('/business')}
              onClose={() => history.replace ('/business')}
            />
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Alterar senha'}
            </Button>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default withRouter (ChangePassword)
