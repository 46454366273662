import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import viaCepApi from '../../../services/viaCepApi'
import MaskedInputService from '../../../services/maskedInput'
import NumberInput from '../../../components/Inputs/NumberInput'
import Select from '../../../components/Inputs/Select'
import AlertDialog from '../../../components/AlertDialog'
import ReactTooltip from 'react-tooltip'
import { Formik } from 'formik'
import professionalPersonalInfoValidator from '../../../validators/professionalPersonalInfoValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { FaStar } from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Form,
  Button,
  BigImage,
  ImageContainer,
  RatingContainer,
  DangerButton,
  SubTitle,
  LinkText,
  FormField,
  Label,
  WarningText,
  ErrorContainer,
  Input,
  MaskedInput,
  Row
} from '../styles'

function EditProfessionalPersonalInfo ({ professional, history }) {
  require ('cleave.js/dist/addons/cleave-phone.br')

  const [loading, setLoading] = useState (true)
  const [loadingAddress, setLoadingAddress] = useState (false)
  const [accessLevels, setAccessLevels] = useState ([])
  const [addressFound, setAddressFound] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [showOkDialog, setShowOkDialog] = useState (false)
  const token = AuthService.getToken ()
  const isBusiness = AuthService.isBusiness ()
  const businessId = AuthService.getBusinessId ()

  useEffect (() => {
    getData ()
  }, [])

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getData = async () => {
    try {
      const { data: accessLevels } = await cutileiApi.get ('/access_levels')
      setAccessLevels (accessLevels)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const searchAddress = async (event, setFieldValue) => {
    const postal_code = event.target.rawValue

    setFieldValue ('postal_code', postal_code)
    
    if (postal_code.length === 8) {
      setLoadingAddress (true)
      try {
        const response = await viaCepApi.get (`/ws/${postal_code}/json`)
        const { data } = response

        setFieldValue ('route', data.logradouro)
        setFieldValue ('district', data.bairro)
        setFieldValue ('city', data.localidade)
        setFieldValue ('state', data.uf)

        setAddressFound (true)
      } catch (error) {
        console.log (error.response)
      } finally {
        setLoadingAddress (false)
      }
    }
  }

  const deleteProfessional = async () => {
    try {
      await cutileiApi.delete (
        `/businesses/${businessId}/professionals/${professional?.id}`, requestConfig
      )
      history.replace ('/business')
    } catch (error) {
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)
  const toggleOkDialog = () => setShowOkDialog (!showOkDialog)

  return (
    <Container>
      {loading ? <Loading style={{marginBottom: 20}}/> : (
        <Formik
          validationSchema={professionalPersonalInfoValidator}
          initialValues={{
            'id': professional?.id,
            'name': professional?.name,
            'nickname': professional?.nickname || '',
            'cpf': professional?.cpf,
            'rg': professional?.rg,
            'order': professional?.number,
            'email': professional?.email,
            'birth': DateTime.fromISO (professional?.birth).toFormat ('ddMMyyyy'),
            'gender': {label: professional?.gender, value: professional?.gender},
            'phone': professional?.phone,
            'access_level': {
              label: professional?.access_level.name,
              value: professional?.access_level
            },
            'route': professional?.addresses[0].route,
            'number': professional?.addresses[0].number,
            'complement': professional?.addresses[0].complement,
            'district': professional?.addresses[0].district,
            'city': professional?.addresses[0].city,
            'state': professional?.addresses[0].state,
            'postal_code': professional?.addresses[0].postal_code
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const {
              name,
              nickname,
              cpf,
              rg,
              order,
              email,
              birth,
              gender,
              phone,
              access_level,
              ...address
            } = values

            setErrorMessage (null)
            setSubmitting (true)

            try {
              await cutileiApi.put (`/businesses/${businessId}/professionals/${professional?.id}`, {
                name,
                nickname,
                cpf,
                rg,
                email,
                number: order,
                birth: DateTime.fromFormat (birth, 'ddMMyyyy').toISODate (),
                gender: gender.value,
                phone,
                access_level_id: access_level.value.id,
                address: { ...address, id: professional?.addresses[0].id}
              }, requestConfig)

              toggleOkDialog ()
              setSubmitting (false)
            } catch (error) {
              setSubmitting (false)
              console.log (error.response.data)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <SubTitle>Dados pessoais</SubTitle>
              <ImageContainer>
                <BigImage src={professional?.profile_picture}/>
                {professional?.ratings_count > 0 && (
                  <RatingContainer>
                    <FaStar size={16} color='#FFC100'/>
                    <SubTitle>
                      {professional?.average_rating}
                    </SubTitle>
                  </RatingContainer>
                )}
              </ImageContainer>
              <Row>
                <LinkText to={professional?.ratings_count === 0 ? '#' : {
                  pathname: `/business/professionals/${professional?.id}/ratings`,
                  state: { professional }
                }}>
                  {`${professional?.ratings_count} ${professional?.ratings_count === 1 ? 'avaliação' : 'avaliações'}`}
                </LinkText>
              </Row>
              <Row>
                <FormField stretch={2}>
                  <Input
                    placeholder='Nome completo'
                    value={values.name}
                    onChange={handleChange ('name')}
                    onBlur={handleBlur ('name')}
                  />
                  {touched.name && errors.name && (
                    <WarningText>
                      {errors.name}
                    </WarningText>
                  )}
                </FormField>
                <FormField stretch={1}>
                  <Input
                    placeholder='Apelido'
                    value={values.nickname}
                    onChange={handleChange ('nickname')}
                    onBlur={handleBlur ('nickname')}
                    data-for='nickname'
                    data-tip
                  />
                  <ReactTooltip id='nickname' effect='solid' backgroundColor='#252525' tooltipRadius='10'>
                    <Label style={{color: '#FFFFFF'}}>
                      Este é o nome que aparecerá na agenda do salão e para os clientes. <br/>
                      Se o campo não for preenchido, o primeiro nome será usado.
                    </Label>
                  </ReactTooltip>
                  {touched.nickname && errors.nickname && (
                    <WarningText>
                      {errors.nickname}
                    </WarningText>
                  )}
                </FormField>
                {isBusiness &&
                  <FormField data-for='access_level_tip' data-tip>
                    <Select
                      name='access_level'
                      placeholder='Nível de acesso...'
                      value={values.access_level}
                      options={accessLevels.map (level => ({ label: level.name, value: level }))}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      error={errors.access_level}
                      touched={touched.access_level}
                    />
                    {values.access_level && 
                      <ReactTooltip id='access_level_tip' effect='solid' backgroundColor='#252525' tooltipRadius='10'>
                        <Label style={{color: '#FFFFFF'}}>
                          {values.access_level.value.description}
                        </Label>
                      </ReactTooltip>
                    }
                    {touched.access_level && errors.access_level && (
                      <WarningText>
                        {errors.access_level}
                      </WarningText>
                    )}
                  </FormField>
                }
                <FormField>
                  <MaskedInput
                    name='cpf'
                    placeholder='CPF'
                    options={{
                      blocks: [3,3,3,2],
                      delimiters: ['.', '.', '-'],
                      numericOnly: true
                    }}
                    value={values.cpf}
                    onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                    onBlur={handleBlur ('cpf')}
                  />
                  {touched.cpf && errors.cpf && (
                    <WarningText>
                      {errors.cpf}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input
                    placeholder='RG (opcional)'
                    value={values.rg}
                    onChange={handleChange ('rg')}
                    onBlur={handleBlur ('rg')}
                  />
                  {touched.rg && errors.rg && (
                    <WarningText>
                      {errors.rg}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <NumberInput
                    name='order'
                    placeholder='Ordem'
                    value={values.order}
                    minValue={1}
                    onChange={setFieldValue}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField stretch={2}>
                  <Input
                    type='email'
                    autoComplete='off'
                    placeholder='E-mail'
                    value={values.email}
                    onChange={handleChange ('email')}
                    onBlur={handleBlur ('email')}
                  />
                  {touched.email && errors.email && (
                    <WarningText>
                      {errors.email}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <MaskedInput
                    name='birth'
                    placeholder='Nascimento'
                    options={{date: true, datePattern: ['d', 'm', 'Y']}}
                    value={values.birth}
                    onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                    onBlur={handleBlur ('birth')}
                  />
                  {touched.birth && errors.birth && (
                    <WarningText>
                      {errors.birth}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Select
                    name='gender'
                    placeholder='Gênero...'
                    value={values.gender}
                    options={[
                      {label: 'Masculino', value: 'Masculino'},
                      {label: 'Feminino', value: 'Feminino'},
                      {label: 'Não informar', value: 'Não informar'}
                    ]}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.gender}
                    touched={touched.gender}
                  />
                  {touched.gender && errors.gender && (
                    <WarningText>
                      {errors.gender}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <MaskedInput
                    name='phone'
                    placeholder='Celular'
                    options={{
                      blocks: [0, 2, 5, 4],
                      delimiters: ['(', ') ', '-'],
                      numericOnly: true,
                    }}
                    value={values.phone}
                    onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                    onBlur={handleBlur ('phone')}
                  />
                  {touched.phone && errors.phone && (
                    <WarningText>
                      {errors.phone}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <SubTitle>Endereço</SubTitle>
              <Row>
                <FormField>
                  <MaskedInput
                    name='postal_code'
                    placeholder='CEP'
                    options={{
                      blocks: [5,3],
                      delimiters: ['-'],
                      numericOnly: true
                    }}
                    value={values.postal_code}
                    onChange={event => searchAddress (event, setFieldValue)}
                    onBlur={handleBlur ('postal_code')}
                  />
                  {touched.postal_code && errors.postal_code && (
                    <WarningText>
                      {errors.postal_code}
                    </WarningText>
                  )}
                </FormField>
                <FormField stretch={2}>
                  <Input
                    placeholder='Rua/avenida'
                    value={values.route}
                    onChange={handleChange ('route')}
                    onBlur={handleBlur ('route')}
                    disabled
                  />
                </FormField>
                <FormField>
                  <Input
                    placeholder='Número'
                    value={values.number}
                    onChange={handleChange ('number')}
                    onBlur={handleBlur ('number')}
                  />
                  {touched.number && errors.number && (
                    <WarningText>
                      {errors.number}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input
                    placeholder='Complemento'
                    value={values.complement}
                    onChange={handleChange ('complement')}
                    onBlur={handleBlur ('complement')}
                  />
                </FormField>
              </Row>
              <Row>
                <FormField stretch={2}>
                  <Input
                    placeholder='Bairro'
                    value={values.district}
                    onChange={handleChange ('district')}
                    onBlur={handleBlur ('district')}
                    disabled
                  />
                </FormField>
                <FormField>
                  <Input
                    placeholder='Cidade'
                    value={values.city}
                    onChange={handleChange ('city')}
                    onBlur={handleBlur ('city')}
                    disabled
                  />
                </FormField>
                <FormField>
                  <Input
                    placeholder='Estado'
                    value={values.state}
                    onBlur={handleBlur ('state')}
                    disabled
                  />
                </FormField>
              </Row>
              {errorMessage && (
                <ErrorContainer>
                  <p>{errorMessage}</p>
                </ErrorContainer>
              )}
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
              {isBusiness &&
                <DangerButton type='button' onClick={toggleAlertDialog}>
                  Excluir profissional
                </DangerButton>
              }
            </Form>
          )}
        </Formik>
      )}
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este profissional?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteProfessional}
        onClose={toggleAlertDialog}
        dangerous
      />
      <AlertDialog
        visible={showOkDialog}
        title='Sucesso'
        message='Alterações salvas!'
        confirmText='Ok'
        onConfirm={toggleOkDialog}
        onClose={toggleOkDialog}
      />
    </Container>
  )
}

export default withRouter (EditProfessionalPersonalInfo)
