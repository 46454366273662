import React from 'react'
import Business from './Business'
import { Container } from './styles'

function BusinessList ({ businesses }) {
  return (
    <Container>
      {businesses.map (business => (
      <Business key={business.id} data={business}/>
    ))}
    </Container>
  )
}

export default BusinessList
