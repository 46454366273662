import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Dashboard from '../../../../services/Dashboard'
import PieChart from '../../../../components/Charts/PieChart'
import ColumnChart from '../../../../components/Charts/ColumnChart'
import Select from '../../../../components/Inputs/Select'
import SoldItemList from '../../../../components/Lists/SoldItemList'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  selectStyle,
  BigInfoContainer,
  ModalContainer,
  FilterContainer,
  LoadingContainer,
  CloseButton,
  SubTitle,
  InfoText,
  LinkText,
  TabRow,
  TabButton,
  Column
} from '../../styles'

function SoldItems ({
  data: soldItems,
  selectedMonth = DateTime.now ().month,
  selectedYear = DateTime.now ().year
}) {
  const now = DateTime.now ().setLocale ('pt-BR')
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const periodFilterOptions = Dashboard.PERIOD_FILTERS
  const itemFilterOptions = Dashboard.ITEM_FILTERS
  const [loading, setLoading] = useState (true)
  const [option, setOption] = useState ('chart')
  const [isMounted, setMounted] = useState (false)
  const [showDetails, setShowDetails] = useState (false)
  const [soldItemsByMonth, setSoldItemsByMonth] = useState (null)
  const [periodFilter, setPeriodFilter] = useState (null)
  const [itemFilter, setItemFilter] = useState (Dashboard.ITEM_FILTERS[0])
  const [monthFilter, setMonthFilter] = useState ({
    label: now.toFormat ('MMM yyyy'),
    value: now.toFormat ('MMM yyyy')
  })

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (isMounted) setPeriodFilter (periodFilterOptions[0])
  }, [isMounted])

  useEffect (() => {
    getData ()
  }, [periodFilter])

  const getData = async () => {
    if (showDetails) try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = now.toISODate ()

      const filters = `start_date=${startDate}&end_date=${endDate}`
        
      const { data: soldItemsByMonth } = await cutileiApi.get (
        `/businesses/${businessId}/sold_items?${filters}`, requestConfig
      )

      setSoldItemsByMonth (soldItemsByMonth)
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const toggleDetails = () => {
    if (!isMounted) setMounted (true)
    setShowDetails (!showDetails)
  }

  const bestSellers = (a, b) => b.count - a.count

  return (
    <BigInfoContainer>
      <SubTitle>Itens vendidos - {now.toFormat ('MMMM / yyyy')}</SubTitle>
      <PieChart data={soldItems} format='amount'/>
      <LinkText onClick={toggleDetails}>
        Ver histórico
      </LinkText>
      <Modal
        isOpen={showDetails}
        onRequestClose={toggleDetails}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <ModalContainer>
          <SubTitle>Itens vendidos</SubTitle>
          <CloseButton onClick={toggleDetails}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
          <FilterContainer>
            <Select
              placeholder='Período...'
              value={periodFilter}
              options={periodFilterOptions}
              onChange={(_, option) => setPeriodFilter (option)}
              containerStyles={{...selectStyle, width: 250}}
            />
          </FilterContainer>
          {loading ? (
            <LoadingContainer>
              <Loading/>
              <InfoText>Gerando gráfico...</InfoText>
            </LoadingContainer>
          ) : (
            <Column>
              <TabRow style={{marginTop: 12}}>
                <TabButton
                  selected={option === 'chart'}
                  color={option === 'chart' ? '#FFC100' : '#FFFFFF'}
                  onClick={() => setOption ('chart')}
                >
                  Gráfico geral
                </TabButton>
                <TabButton
                  selected={option === 'list'}
                  color={option === 'list' ? '#FFC100' : '#FFFFFF'}
                  onClick={() => setOption ('list')}
                >
                  Lista de itens
                </TabButton>
              </TabRow>
              {option === 'chart' ? (
                <ColumnChart
                  data={[
                    ['Itens', 'Serviços', 'Pacotes', 'Produtos'],
                    ...Object.keys (soldItemsByMonth).map (month => ([
                      month,
                      soldItemsByMonth[month].services.total,
                      soldItemsByMonth[month].bundles.total,
                      soldItemsByMonth[month].products.total
                    ]))
                  ]}
                  options={{
                    isStacked: false,
                    height: 400,
                    chartArea: {
                      top: 10, left: 90, width: '73%', height: '80%'
                    },
                    vAxis: {
                      title: 'Qtd. vendida',
                      titleTextStyle: { bold: true }
                    }
                  }}
                />
              ) : (
                <Column>
                  <FilterContainer style={{marginTop: -7}}>
                    <Select
                      placeholder='Período...'
                      value={monthFilter}
                      options={Object.keys (soldItemsByMonth).map (month => ({
                        label: month, value: month
                      }))}
                      onChange={(_, option) => setMonthFilter (option)}
                      containerStyles={selectStyle}
                    />
                    <Select
                      placeholder='Item...'
                      value={itemFilter}
                      options={itemFilterOptions}
                      onChange={(_, option) => setItemFilter (option)}
                      containerStyles={selectStyle}
                    />
                  </FilterContainer>
                  <SoldItemList
                    items={soldItemsByMonth[monthFilter.value][itemFilter.value].list.sort (bestSellers)}
                  />
                </Column>
              )}
            </Column>
          )}
        </ModalContainer>
      </Modal>
    </BigInfoContainer>
  )
}

export default SoldItems
