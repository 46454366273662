import { DateTime } from 'luxon'
import React from 'react'
import { withRouter } from 'react-router-dom'
import Phone from '../../../services/Phone'
import AdminNavbar from '../../../components/AdminNavbar'
import Address from '../../../services/Address'
import CutileiLogo from '../../../icons/logo-cutilei-simple.png'

import {
  Container,
  Info,
  Image,
  Row,
  Title,
  SubTitle,
  InfoText,
  LinkText,
  Block,
  MoneyText
} from './styles'

function Member ({ history }) {
  const { member } = history.location.state
  const { canceled_subscriptions } = member
  const address = Address.formatAddress (member.addresses[0])
  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`

  return (
    <Container>
      <AdminNavbar/>
      <Info>
        <Title>{member.name}</Title>
        <Image src={member.profile_picture ?? CutileiLogo}/>
        {member.sponsored &&
          <MoneyText style={{fontSize: 18, marginBottom: 5}}>
            Embaixador(a)
          </MoneyText>
        }
        <Block>
          <Row>
            <SubTitle>E-mail:</SubTitle>
            <InfoText>{member.email}</InfoText>
          </Row>
          <Row>
            <SubTitle>Contato:</SubTitle>
            <InfoText>{Phone.formatPhone (member.phone)}</InfoText>
          </Row>
          <Row>
            <SubTitle>CPF:</SubTitle>
            <InfoText>{member.cpf}</InfoText>
          </Row>
          <Row>
            <SubTitle>Gênero:</SubTitle>
            <InfoText>{member.gender}</InfoText>
          </Row>
          <Row>
            <SubTitle>Nascimento:</SubTitle>
            <InfoText>
              {DateTime.fromISO (member.birth).toFormat ('dd/MM/yyyy')}
            </InfoText>
          </Row>
        </Block>
        <Block>
          <SubTitle>Endereço:</SubTitle>
          <LinkText href={addressUrl} target='_blank' rel='noopener noreferrer'>
            <SubTitle>{address}</SubTitle>
          </LinkText>
        </Block>
        <Block>
          <Row>
            <SubTitle>Cadastro:</SubTitle>
            <InfoText>
              {DateTime.fromISO (member.created_at).toFormat ('dd/MM/yyyy - H:mm:ss')}
            </InfoText>
          </Row>
          <Row>
            <SubTitle>Última alteração:</SubTitle>
            <InfoText>
              {DateTime.fromISO (member.updated_at).toFormat ('dd/MM/yyyy - H:mm:ss')}
            </InfoText>
          </Row>
        </Block>
        {canceled_subscriptions && (
          <Block style={{marginBottom: 5}}>
            <SubTitle style={{color: '#FF3939', marginBottom: 5}}>
              Cliente cancelado
            </SubTitle>
            <Row>
              <SubTitle>Motivo do cancelamento:</SubTitle>
              <InfoText>
                {canceled_subscriptions[0].reason}
              </InfoText>
            </Row>
            {canceled_subscriptions[0].comment && (
              <Row>
                <SubTitle>Comentário:</SubTitle>
                <InfoText>
                  {canceled_subscriptions[0].comment}
                </InfoText>
              </Row>
            )}
          </Block>
        )}
      </Info>
    </Container>
  )
}

export default withRouter (Member)
