import styled from 'styled-components'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    minWidth: 360,
    maxWidth: 600,
    padding: 0,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 600px;
  background: #F7F7F7;
  border-radius: 15px;
  overflow: hidden;
  padding: 10px;
  justify-content: center;
  align-items: center;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

export const Image = styled.img`
  width: 110px;
  margin: 10px 0px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
`

export const ButtonText = styled(SubTitle)`
  color: #FFFFFF;
  margin: 0px;
  font-weight: 200;
`

export const Button = styled.button`
  background-color: #252525;
  color: #F7F7F7;
  width: 100px;
  height: 40px;
  padding: 8px;
  border-radius: 15px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 10px 5px 0px;
  font-size: 14px;
  border: none;

  &:hover {
    background-color: #252525;
    color: #F7F7F7;
  }
`
