import React from 'react'

import {
  Container,
  Row,
  SubTitle,
  InfoText
} from './styles'

function SoldItem ({ data: item }) {
  return (
    <Container>
      <InfoText>
        {item.name}
      </InfoText>
      <SubTitle>
        {item.count}
      </SubTitle>
    </Container>
  )
}

export default SoldItem
