import React from 'react'
import Rating from './Rating'
import { Container } from './styles'

function RatingList ({ ratings }) {
  return (
    <Container>
      {ratings.map (rating =>
        <Rating key={rating.id} data={rating}/>
      )}
    </Container>
  )
}

export default RatingList
