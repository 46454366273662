import React from 'react'
import Member from './Member'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  SubTitle
} from './styles'

function MemberList ({
  members,
  hasMore,
  totalMembers,
  onScroll: handleScroll
}) {
  return (
    <Container>
      <SubTitle>
        {totalMembers + ' ' + (totalMembers === 1 ? 'Resultado' : 'Resultados')}
      </SubTitle>
      <InfiniteScroll
        dataLength={members.length}
        next={handleScroll}
        hasMore={hasMore}
        loader={<Loading/>}
        endMessage={<div style={{height: 50}}></div>}
        style={{overflow: 'visible'}}
      >
        {members.map (member =>
          <Member key={member.id} data={member}/>
        )}
      </InfiniteScroll>
    </Container>
  )
}

export default MemberList
