import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Dashboard from '../../../../services/Dashboard'
import Comission from '../../../../services/Comission'
import PieChart from '../../../../components/Charts/PieChart'
import ColumnChart from '../../../../components/Charts/ColumnChart'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  InfoContainer,
  ModalContainer,
  FilterContainer,
  LoadingContainer,
  CloseButton,
  SubTitle,
  InfoText,
  LinkText
} from '../../styles'

function EarningsByCategory ({
  data: earnings,
  selectedMonth = DateTime.now ().month,
  selectedYear = DateTime.now ().year
}) {
  const now = DateTime.now ().setLocale ('pt-BR')
  const formattedNow = now.toFormat ('MMM yyyy')
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const periodFilterOptions = Dashboard.PERIOD_FILTERS
  const [loading, setLoading] = useState (true)
  const [isMounted, setMounted] = useState (false)
  const [periodFilter, setPeriodFilter] = useState (null)
  const [showDetails, setShowDetails] = useState (false)
  const [earningsByMonth, setEarningsByMonth] = useState ([])

  const earningsByCategory = [
    ['Categoria de serviço', 'Valor (R$)'],
    ...Object.keys (Object.values (earnings)[0]).map (category => ([
      category, earnings[formattedNow][category].totalValue
    ]))
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (isMounted) setPeriodFilter (periodFilterOptions[0])
  }, [isMounted])

  useEffect (() => {
    getData ()
  }, [periodFilter])

  const getData = async () => {
    if (showDetails) try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = now.toISODate ()
      const filters = `start_date=${startDate}&end_date=${endDate}`

      const { data: earningsByCategory } = await cutileiApi.get (
        `/businesses/${businessId}/earnings_by_category?${filters}`, requestConfig
      )

      const categories = Dashboard.getKeysFromPeriod (earningsByCategory)

      setEarningsByMonth ([
        ['Categoria de serviço', ...categories],
        ...Object.values (earningsByCategory).map ((month, index) => ([
          Object.keys (earningsByCategory)[index],
          ...categories.map (name => month[name]?.totalValue || 0)
        ]))
      ])
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const handlePeriodFilterSelected = option => setPeriodFilter (option)

  const toggleDetails = () => {
    if (!isMounted) setMounted (true)
    setShowDetails (!showDetails)
  }

  return (
    <InfoContainer>
      <SubTitle>Faturamento por categoria de serviço</SubTitle>
      <PieChart data={earningsByCategory}/>
      <LinkText onClick={toggleDetails}>
        Ver histórico
      </LinkText>
      <Modal
        isOpen={showDetails}
        onRequestClose={toggleDetails}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <ModalContainer>
          <SubTitle>Faturamento por categoria de serviço</SubTitle>
          <CloseButton onClick={toggleDetails}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
          <FilterContainer>
            <Select
              name='periodFilter'
              placeholder='Período...'
              value={periodFilter}
              options={periodFilterOptions}
              onChange={(_, option) => handlePeriodFilterSelected (option)}
              containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, width: 250}}
            />
          </FilterContainer>
          {loading ? (
            <LoadingContainer>
              <Loading/>
              <InfoText>Gerando gráfico...</InfoText>
            </LoadingContainer>
          ) : (
            <ColumnChart data={earningsByMonth} options={{
              chartArea: {
                top: 35, left: 110, width: '60%', height: '80%'
              }
            }}/>
          )}
        </ModalContainer>
      </Modal>
    </InfoContainer>
  )
}

export default EarningsByCategory
