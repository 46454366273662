import { DateTime } from 'luxon'

export default class Dashboard {
  static PERIOD_FILTERS = [
    {label: '3 meses', value: 2},
    {label: '6 meses', value: 5},
    {label: '12 meses', value: 11},
    {label: 'Este ano', value: DateTime.now ().diff (DateTime.now ().startOf ('year'), 'months').months}
  ]

  static TYPE_FILTERS = [
    {label: 'Todos os rendimentos', value: 'all_earnings'},
    {label: 'Agendamentos Cutilei', value: 'cutilei'},
    {label: 'Comandas do salão', value: 'bill'}
  ]

  static ITEM_FILTERS = [
    {label: 'Serviços', value: 'services'},
    {label: 'Pacotes', value: 'bundles'},
    {label: 'Produtos', value: 'products'}
  ]

  static groupEarningsByMonth (data) {
    return data.reduce ((acc, curr) => {
      const month = DateTime.fromISO (curr.date).setLocale ('pt-BR').toFormat ('MMM yyyy')
      if (!acc[month]) {
        acc[month] = []
      }
      acc[month].push (curr)
      return acc
    }, {})
  }

  static getKeysFromPeriod (earnings) {
    return Array.from (
      new Set (Object.values (earnings).flatMap (month => (
        Object.keys (month)
      )))
    )
  }

  static getEarningsByPaymentMethod (earnings, paymentMethod) {
    return earnings.filter (e => e.type === 'bill').reduce ((total, bill) => (
      total + bill.payments.reduce ((total, payment) => (
        total + (payment.payment_method.codename === paymentMethod.codename ? payment.value : 0)
      ), 0)
    ), 0)
  }
}
