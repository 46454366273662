import React from 'react'
import SoldItem from './SoldItem'

import {
  Container,
  SubTitle,
  LabelRow
} from './styles'

function SoldItemList ({ items }) {
  return (
    <Container>
      <LabelRow>
        <SubTitle>Nome</SubTitle>
        <SubTitle>Qtd. vendida</SubTitle>
      </LabelRow>
      {items.map ((item, index) => (
        <SoldItem key={index} data={item}/>
      ))}
    </Container>
  )
}

export default SoldItemList
