import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import Navbar from '../../../components/Navbar'
import RatingList from '../../../components/Lists/RatingList'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { Container, Title } from '../styles'

function ProfessionalRatings ({ history }) {
  const [loading, setLoading] = useState (true)
  const [professionalRatings, setProfessionalRatings] = useState ([])
  const { professional } = history.location.state
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getBusinesses ()
  }, [])

  const getBusinesses = async () => {
    try {
      setLoading (true)
      const { data: professionalRatings } = await cutileiApi.get (
        `/professionals/${professional.id}/ratings?business_id=${businessId}`, requestConfig
      )
      setProfessionalRatings (professionalRatings)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <Navbar/>
      <Title>
        Avaliações de {professional.nickname || professional.name.split (' ')[0]}
      </Title>
      {loading ? <Loading/> :
        <RatingList ratings={professionalRatings}/>
      }
    </Container>
  )
}

export default withRouter (ProfessionalRatings)
