import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: inline-block;
  background-color: #FFFFFF;
  width: 60px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  z-index: 3;

  &:hover {
    background-color: #F0F0F0;
  }

  &:hover div {display: block}

  div {
    background: #212529;
    display: none;
    position: absolute;
    top: 79px;
    right: -70px;
    min-width: 200px;
    box-shadow: 0px 2px 15px -8px #252525;
    border-radius: 15px;

    &:before {
      content: '';
      background: #FFC100;
      position: absolute;
      top: -4px;
      left: 95px;
      width: 10px;
      height: 10px;
      z-index: -1;
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      background: transparent;
      position: absolute;
      top: -60px;
      left: 60px;
      width: 75px;
      height: 85px;
      z-index: -5;
    }
  }
`;

export const RatingContainer = styled.span`
  display: flex;
  z-index: 6;
  position: absolute;
  top: -8px;
  right: -8px;
  height: 18px;
  padding: 0px 6px 0px 4px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  background-color: #FFFFFF;

  p {
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    margin: 0px;
  }
`

export const Option = styled.button`
  background-color: #FFFFFF;
  display: block;
  padding: 10px;
  border: none;
  box-shadow: none;
  width: 100%;
  z-index: -1;

  &:hover {
    background-color: #F7F7F7;
  }

  &:first-child {
    border-top-left-radius: 13px;
    border-top-right-radius: 13px;
  }

  &:last-child {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: 55px;
  border-radius: 10px;
  object-fit: cover;
  border: 1px solid #CCCCCC;
  z-index: 5;
`

export const InfoText = styled.p`
  font-size: 14px;
  text-align: center;
  color: #000000;
  margin: 3px 5px 5px;
`

export const SubTitle = styled(InfoText)`
  font-weight: 500;
  margin: 0px;
  align-self: center;
`;

export const SmallText = styled(InfoText)`
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
`
