import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import Phone from '../../../services/Phone'
import CustomerService from '../../../services/Customer'
import Select from '../../../components/Inputs/Select'
import SelectAsync from '../../../components/Inputs/SelectAsync'
import AddCustomerModal from '../AddCustomerModal'
import EditCustomerModal from '../EditCustomerModal'
import createScheduleValidator from '../../../validators/createScheduleValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  DangerButton,
  Row,
  ScheduleRow,
  InfoText,
  WarningText,
  ErrorContainer,
  Label
} from './styles'

function CreateScheduleModal ({
  visible,
  data,
  professionals,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const { date, time, professional, customer } = data
  const formattedDate = date.toFormat ('yyyy-MM-dd')
  const [loading, setLoading] = useState (true)
  const [errorMessage, setErrorMessage] = useState (null)
  const [customerInput, setCustomerInput] = useState ('')
  const [customerQuery, setCustomerQuery] = useState ('')
  const [showAddCustomerModal, setShowAddCustomerModal] = useState (false)
  const [showEditCustomerModal, setShowEditCustomerModal] = useState (false)
  const [services, setServices] = useState ([])
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (!visible) setCustomerQuery ('')
    getData (professional)
  }, [visible, professional])

  const getData = async (_professional = professional) => {
    setErrorMessage (null)
    
    try {
      if (_professional) {
        const { data: services } = await cutileiApi.get (
          `/businesses/${businessId}/professionals/${_professional.id}/services?cutilei_services=false`,
          requestConfig
        )
  
        setServices (services.map (service => ({
          label: service.nickanme || service.name,
          value: service
        })))
      }
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const getCustomers = async query => {
    try {
      const { data: customers } = await cutileiApi.get (
        `/businesses/${businessId}/customers?query=${query}`, requestConfig
      )

      const sortedCustomers = CustomerService.sortCustomersByQuery (customers.data, query)

      return sortedCustomers.map (customer => ({
        label: `${customer.name}${customer.phone && ` - ${Phone.formatPhone (customer.phone)}`}`,
        value: customer.id,
        data: customer
      }))
    } catch (error) {
      console.log (error)
    }
  }

  const getTimeWindows = async (name, professional, service, setFieldValue) => {
    try {
      const filters = `date=${formattedDate}&business_id=${businessId}&service_id=${service.value.id}`

      const { data: professionalFreeSchedules } = await cutileiApi.get (
        `/professionals/${professional.id}/free_schedules?${filters}`, requestConfig
      )
  
      setFieldValue (
        `${name.split ('.')[0]}.timeWindows`, professionalFreeSchedules.map (timeWindow => ({
          label: timeWindow, value: timeWindow
        }))
      )
    } catch (error) {
      console.log (error.response)
    }
  }

  const handleCustomerSelected = (value, schedules, setFieldValue) => {
    setFieldValue ('schedules', schedules.map (schedule => ({
      ...schedule, customer: value,
    })))
  }

  const handleServiceSelected = async (name, professional, service, setFieldValue) => {
    setFieldValue (name, service)
    if (professional) getTimeWindows (name, professional.value, service, setFieldValue)
  }

  const handleProfessionalSelected = async (name, professional, service, setFieldValue) => {
    setFieldValue (name, professional)
    getData (professional.value)
    if (service) getTimeWindows (name, professional.value, service, setFieldValue)
  }

  const handleAddSchedule = (schedules, setFieldValue) => {
    const schedule = schedules[0]
    setFieldValue ('schedules', [
      ...schedules,
      {
        customer: schedule.customer,
        professional: null,
        service: null,
        date: schedule.date,
        time: null
      }
    ])
  }

  const handleDeleteSchedule = (schedules, index, setFieldValue) => {
    setFieldValue (
      'schedules',
      schedules.map ((s, i) => ({...s, idx: i})).filter (s => s.idx !== index)
    )
  }

  const getNoOptionsMessage = service => (
    service
      ? 'Nenhum horário disponível'
      : 'Selecione o serviço para obter os horários disponíveis'
  )

  const toggleAddCustomerModal = () => setShowAddCustomerModal (!showAddCustomerModal)
  const toggleEditCustomerModal = () => setShowEditCustomerModal (!showEditCustomerModal)

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      {loading ? <Loading/> : (
        <Formik
          validationSchema={createScheduleValidator}
          initialValues={{
            'schedules': [{
              'customer': customer ? {
                label: customer.name,
                value: customer.id
              } : null,
              'professional': professional ? {
                label: professional.nickname || professional.name?.split (' ')[0],
                value: professional
              } : null,
              'service': null,
              'date': formattedDate,
              'time': time ? {label: time, value: time} : null,
              'timeWindows': []
            }]
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              let createdSchedules = []
              for (let i = 0; i < values.schedules.length; i++) {
                const schedule = values.schedules[i]
                const scheduleValues = {
                  businessId,
                  date: schedule.date,
                  time: schedule.time.value,
                  customerId: schedule.customer.value,
                  serviceId: schedule.service.value.id,
                  professionalId: schedule.professional.value.id
                }
  
                const { data: createdSchedule } = await cutileiApi.post ('/schedules', scheduleValues, requestConfig)
                createdSchedules.push (createdSchedule)
              }

              setSubmitting (false)
              handleConfirm (createdSchedules)
              handleClose ()
            } catch (error) {
              console.log (error)
              if (error.response.data) setErrorMessage (error.response.data.message)
              setSubmitting (false)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit
          }) => {
            const firstSchedule = values.schedules[0]
            const firstScheduleTouched = touched.schedules?.[0]
            const firstScheduleErrors = errors.schedules?.[0]

            return (
              <Form onSubmit={handleSubmit}>
                <Title>Agendar</Title>
                <CloseButton onClick={handleClose}>
                  <FA.FaTimes color='#FF3939' size={18}/>
                </CloseButton>
                <Row>
                  <FormField>
                    <Input
                      name='date'
                      placeholder='Data'
                      value={DateTime.fromISO (firstSchedule.date).toFormat ('dd/MM/yyyy')}
                      onChange={handleChange ('date')} 
                      onBlur={handleBlur ('date')}
                      disabled={date !== undefined}
                    />
                  </FormField>
                  <FormField>
                    <SelectAsync
                      name='customer'
                      placeholder='Cliente...'
                      value={firstSchedule.customer}
                      loadOptions={getCustomers}
                      onChange={(_, value) => handleCustomerSelected (value, values.schedules, setFieldValue)}
                      inputValue={customerInput}
                      onInputChange={setCustomerInput}
                      onMenuClose={() => setCustomerQuery(customerInput)}
                      onFocus={() => {
                        setCustomerInput (firstSchedule.customer ? '' : customerQuery)
                        setCustomerQuery ('')
                      }}
                      blurInputOnSelect
                      onBlur={setFieldTouched}
                      error={firstScheduleErrors?.customer}
                      touched={firstScheduleTouched?.customer}
                      DropdownIndicator={() => <FA.FaSearch style={{marginRight: 12}}/>}
                      onMenuOpen={() => setFieldValue ('customer', null)}
                      containerStyles={{width: 320}}
                    />
                    {firstScheduleTouched?.customer && firstScheduleErrors?.customer && (
                      <WarningText>
                        {firstScheduleErrors.customer}
                      </WarningText>
                    )}
                  </FormField>
                  <Button
                    type='button'
                    onClick={firstSchedule.customer ? toggleEditCustomerModal : toggleAddCustomerModal}
                    style={{backgroundColor: '#606060', width: 135, alignSelf: 'flex-start'}}
                  >
                    {firstSchedule.customer ? 'Editar dados' : 'Novo cliente'}
                  </Button>
                </Row>
                {values.schedules.map ((schedule, index) => (
                  <ScheduleRow
                    key={index}
                    index={index}
                    service={schedule.service}
                    professional={schedule.professional}
                  >
                    <FormField>
                      <Select
                        name={`schedules[${index}].professional`}
                        placeholder='Profissional...'
                        value={schedule.professional}
                        options={professionals.map (professional => ({
                          label: professional.nickname || professional.name.split (' ')[0],
                          value: professional
                        }))}
                        onChange={(name, option) => {
                          setFieldValue (`schedules[${index}].time`, null)
                          handleProfessionalSelected (name, option, schedule.service, setFieldValue)
                        }}
                        onBlur={setFieldTouched}
                        error={errors.schedules?.[index]?.professional}
                        touched={touched.schedules?.[index]?.professional}
                      />
                      {touched.schedules?.[index]?.professional && errors.schedules?.[index]?.professional && (
                        <WarningText>
                          {errors.schedules?.[index]?.professional}
                        </WarningText>
                      )}
                    </FormField>
                    <FormField>
                      <Select
                        name={`schedules[${index}].service`}
                        placeholder='Serviço...'
                        value={schedule.service}
                        options={services}
                        onChange={(name, option) => {
                          handleServiceSelected (name, schedule.professional, option, setFieldValue)
                        }}
                        onBlur={setFieldTouched}
                        error={errors.schedules?.[index]?.service}
                        touched={touched.schedules?.[index]?.service}
                      />
                      {touched.schedules?.[index]?.service && errors.schedules?.[index]?.service ? (
                        <WarningText>
                          {errors.schedules[index].service}
                        </WarningText>
                      ) : schedule.service && (
                        <Label>
                          Tempo do serviço: <b>{schedule.service.value.duration} minutos</b>
                        </Label>
                      )}
                    </FormField>
                    {(index === 0 || schedule.professional && schedule.service) && (
                      <FormField>
                        <Select
                          name={`schedules[${index}].time`}
                          placeholder='Horário...'
                          value={schedule.time}
                          options={schedule.timeWindows}
                          noOptionsMessage={getNoOptionsMessage (schedule.service)}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          error={errors.schedules?.[index]?.time}
                          touched={touched.schedules?.[index]?.time}
                        />
                      </FormField>
                    )}
                    {index > 0 && (
                      <DangerButton onClick={() => {
                        handleDeleteSchedule (values.schedules, index, setFieldValue)
                      }}>
                        <FA.FaTrash color='#FFFFFF' size={12} style={{marginBottom: 4}}/>
                      </DangerButton>
                    )}
                  </ScheduleRow>
                ))}
                <Button
                  type='button'
                  onClick={() => handleAddSchedule (values.schedules, setFieldValue)}
                  style={{backgroundColor: '#606060', width: 150, marginBottom: 15}}
                >
                  Adicionar serviço
                </Button>
                {errorMessage && (
                  <ErrorContainer>
                    <InfoText>{errorMessage}</InfoText>
                  </ErrorContainer>
                )}
                <AddCustomerModal
                  visible={showAddCustomerModal}
                  query={customerQuery}
                  onConfirm={customer =>
                    handleCustomerSelected (
                      {label: customer.name, value: customer.id},
                      values.schedules,
                      setFieldValue
                    )
                  }
                  onClose={toggleAddCustomerModal}
                />
                {firstSchedule.customer && (
                  <EditCustomerModal
                    visible={showEditCustomerModal}
                    data={firstSchedule.customer.data}
                    onConfirm={customer => {
                      handleCustomerSelected (
                        {label: customer.name, value: customer.id, data: customer},
                        values.schedules,
                        setFieldValue
                      )
                    }}
                    onClose={toggleEditCustomerModal}
                  />
                )}
                <Button type='submit' disabled={isSubmitting}>
                  {isSubmitting ? <ButtonLoading/> : 'Agendar'}
                </Button>
              </Form>
            )
          }}
        </Formik>
      )}
    </Modal>
  )
}

export default CreateScheduleModal
