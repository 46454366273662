import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthService from '../../services/auth'

function PrivateRoute ({ component: Component, ...rest }) {
  const isSigned = AuthService.isSigned ()
  const isBusiness = AuthService.isBusiness ()
  const isProfessional = AuthService.isProfessional ()
  const isAdmin = AuthService.isAdmin ()
  
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isSigned && (isBusiness || isProfessional || isAdmin) ? (
          <Component/>
        ) : (
          <Redirect 
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
