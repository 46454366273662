import React, { useState } from 'react'
import Modal from 'react-modal'
import { Formik } from 'formik'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import CutileiReservation from '../../../services/CutileiReservation'
import Select from '../../../components/Inputs/Select'
import cutileiReservationValidator from '../../../validators/cutileiReservationValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function CreateCutileiReservationModal ({
  visible,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={cutileiReservationValidator}
        initialValues={{
          'description': null,
          'startDate': '',
          'endDate': '',
          'period': null
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setErrorMessage (null)

            const reservationData = {
              ...values,
              description: values.description.value
            }

            const { data: reservation } = await cutileiApi.post (
              `/businesses/${businessId}/cutilei_reservations`, reservationData, requestConfig
            )

            setSubmitting (false)
            handleConfirm (reservation)
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            setErrorMessage (error.response?.data.message)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Reservar agenda para a Cutilei</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField>
                <Select
                  name='description'
                  placeholder='Descrição da reserva...'
                  value={values.description}
                  options={CutileiReservation.DESCRIPTIONS}
                  onChange={(name, option) => {
                    CutileiReservation.handleDescriptionChange (name, option, setFieldValue)
                  }}
                  onBlur={setFieldTouched}
                  error={errors.description}
                  touched={touched.description}
                />
                {touched.description && errors.description && (
                  <WarningText>
                    {errors.description}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Input
                  type='date'
                  placeholder='Data de início'
                  value={values.startDate}
                  onChange={handleChange ('startDate')}
                  onBlur={handleBlur ('startDate')}
                />
                {touched.startDate && errors.startDate && (
                  <WarningText>
                    {errors.startDate}
                  </WarningText>
                )}
              </FormField>
              <FormField style={{marginLeft: 5, marginRight: values.period?.value === -2 ? 5 : 0}}>
                <Select
                  name='period'
                  placeholder='Período...'
                  value={values.period}
                  options={CutileiReservation.PERIODS}
                  onChange={(name, option) => {
                    CutileiReservation.handlePeriodChange (name, option, values, setFieldValue)
                  }}
                  onBlur={setFieldTouched}
                  error={errors.period}
                  touched={touched.period}
                />
                {touched.period && errors.period && (
                  <WarningText>
                    {errors.period}
                  </WarningText>
                )}
              </FormField>
              {values.period?.value === -2 &&
                <FormField>
                  <Input
                    type='date'
                    placeholder='Data final'
                    value={values.endDate}
                    onChange={handleChange ('endDate')}
                    onBlur={handleBlur ('endDate')}
                  />
                  {touched.endDate && errors.endDate && (
                    <WarningText>
                      {errors.endDate}
                    </WarningText>
                  )}
                </FormField>
              }
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Criar reserva'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default CreateCutileiReservationModal
