import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Dashboard from '../../../../services/Dashboard'
import PieChart from '../../../../components/Charts/PieChart'
import ColumnChart from '../../../../components/Charts/ColumnChart'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  InfoContainer,
  ModalContainer,
  FilterContainer,
  LoadingContainer,
  CloseButton,
  SubTitle,
  InfoText,
  LinkText
} from '../../styles'

function CreditsAndDebts ({
  credits,
  debts,
  selectedMonth = DateTime.now ().month,
  selectedYear = DateTime.now ().year
}) {
  const now = DateTime.now ()
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const periodFilterOptions = Dashboard.PERIOD_FILTERS
  const [loading, setLoading] = useState (true)
  const [isMounted, setMounted] = useState (false)
  const [periodFilter, setPeriodFilter] = useState (null)
  const [showDetails, setShowDetails] = useState (false)
  const [creditsAndDebts, setCreditsAndDebts] = useState ([])

  const monthCreditsAndDebts = [
    ['Origem', 'Valor (R$)'],
    ['Créditos', credits.reduce ((total, credit) => total + credit.value, 0)],
    ['Dívidas', debts.reduce ((total, debt) => total + debt.value, 0)]
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (isMounted) setPeriodFilter (periodFilterOptions[0])
  }, [isMounted])

  useEffect (() => {
    getData ()
  }, [periodFilter])

  const getData = async () => {
    if (showDetails) try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = now.toISODate ()

      const filters = `?business_id=${businessId}&start_date=${startDate}&end_date=${endDate}`
                    + '&customer=all_customers'
        
      const { data: credits } = await cutileiApi.get (`/businesses/${businessId}/credits${filters}`, requestConfig)
      const { data: debts } = await cutileiApi.get (`/businesses/${businessId}/debts${filters}`, requestConfig)

      const creditsByMonth = Dashboard.groupEarningsByMonth (credits.sort (orderCreditsAndDebts))
      const debtsByMonth = Dashboard.groupEarningsByMonth (debts.sort (orderCreditsAndDebts))
      const months = [...new Set (Object.keys ({...creditsByMonth, ...debtsByMonth}))]
      
      setCreditsAndDebts ([
        ['Mês / ano', 'Créditos', 'Dívidas'],
        ...months.map (month => ([
          month,
          creditsByMonth[month]?.reduce ((total, credit) => total + credit.value, 0),
          debtsByMonth[month]?.reduce ((total, debt) => total + debt.value, 0)
        ]))
      ])
    } catch (error) {
      console.log (error)
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const orderCreditsAndDebts = (a, b) => (
    DateTime.fromISO (a.date).toMillis () - DateTime.fromISO (b.date).toMillis ()
  )

  const toggleDetails = () => {
    if (!isMounted) setMounted (true)
    setShowDetails (!showDetails)
  }

  return (
    <InfoContainer>
      <SubTitle>Créditos e dívidas</SubTitle>
      <PieChart
        data={monthCreditsAndDebts}
        options={{colors: ['#44CF6C', '#FF3939']}}
      />
      <LinkText onClick={toggleDetails}>
        Ver histórico
      </LinkText>
      <Modal
        isOpen={showDetails}
        onRequestClose={toggleDetails}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <ModalContainer>
          <SubTitle>Créditos e dívidas</SubTitle>
          <CloseButton onClick={toggleDetails}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
          <FilterContainer>
            <Select
              name='periodFilter'
              placeholder='Período...'
              value={periodFilter}
              options={periodFilterOptions}
              onChange={(_, option) => setPeriodFilter (option)}
              containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, width: 250}}
            />
          </FilterContainer>
          {loading ? (
            <LoadingContainer>
              <Loading/>
              <InfoText>Gerando gráfico...</InfoText>
            </LoadingContainer>
          ) : (
            <ColumnChart
              data={creditsAndDebts}
              options={{
                isStacked: false,
                colors: ['#44CF6C', '#FF3939'],
                chartArea: {
                  top: 35, left: 110, width: '70%', height: '80%'
                },
                vAxis: {
                  title: 'Valor (R$)',
                  titleTextStyle: {
                    bold: true
                  }
                }
              }}
            />
          )}
        </ModalContainer>
      </Modal>
    </InfoContainer>
  )
}

export default CreditsAndDebts
