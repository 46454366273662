import React, { useState } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import AlertDialog from '../../../AlertDialog'
import ServicePromotionModal from '../../../../pages/Modals/ServicePromotionModal'
import Logo from '../../../../icons/logo-cutilei-simple.png'
import { ReactComponent as ButtonLoading } from '../../../../icons/loading2.svg'
import ReactTooltip from 'react-tooltip'
import * as FA from 'react-icons/fa'

import {
  Container,
  Image,
  Row,
  SubTitle,
  InfoText,
  Label,
  MoneyTextMask,
  Button
} from './styles'

function BusinessService ({
  data: service,
  professionalID,
  weekdays = [],
  onEdit,
  onEditPromotion: handleEditPromotion,
  onDelete: handleDelete,
}) {
  const [showAlertDialog, setShowAlertDialog] = useState (false)
  const [showPromotionModal, setShowPromotionModal] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const handleEdit = () => onEdit (service)

  const deleteService = async () => {
    setDeleting (true)

    const url = professionalID
      ? `/businesses/${businessId}/professionals/${professionalID}/services/${service.id}`
      : `/businesses/${businessId}/services/${service.id}`

    try {
      await cutileiApi.delete (url, requestConfig)
      setDeleting (false)
      handleDelete (service)
    } catch (error) {
      setDeleting (false)
      console.log (error)
    }
  }

  const toggleAlertDialog = () => setShowAlertDialog (!showAlertDialog)
  const togglePromotionModal = () => setShowPromotionModal (!showPromotionModal)

  return (
    <Container>
      <Row>
        {service.cutilei_service && <Image src={Logo} alt='Service Cutilei'/>}
        <SubTitle>
          {service.nickname || service.name}
        </SubTitle>
      </Row>
      <InfoText>
        {service.duration} min
      </InfoText>
      <InfoText>
        {service.max_delay} min
      </InfoText>
      <InfoText>
        {service.comission_percentage * 100}%
      </InfoText>
      <MoneyTextMask value={service.price ?? 0} style={{justifySelf: 'center'}}/>
      <Row style={{justifySelf: 'center'}}>
        {!service.cutilei_service && !professionalID && (
          <>
            <Button onClick={togglePromotionModal} data-for={`${service.id}_promotions`} data-tip>
              {service.promotions?.[0] ? (
                <FA.FaCog color='#FFC100' size={18}/>
              ) : (
                <FA.FaPlus color='#FFC100' size={10}/>
              )}
              <FA.FaDollarSign color='#FFC100' size={18}/>
            </Button>
            <ReactTooltip
              id={`${service.id}_promotions`}
              effect='solid'
              place='top'
              backgroundColor='#252525'
              tooltipRadius='10'
            >
              <Label style={{color: '#FFFFFF'}}>
                {service.promotions?.[0] ? 'Editar promoção' : 'Adicionar promoção'}
              </Label>
            </ReactTooltip>
            <ServicePromotionModal
              visible={showPromotionModal}
              data={service}
              weekdays={weekdays}
              professionalID={professionalID}
              onClose={togglePromotionModal}
              onConfirm={service => handleEditPromotion (service)}
            />
          </>
        )}
        <Button onClick={handleEdit}>
          <FA.FaEdit color='#35A7FF' size={18}/>
        </Button>
        {!service.cutilei_service && (
          <Button onClick={toggleAlertDialog}>
            {deleting ? (
              <ButtonLoading style={{margin: 0}}/>
            ) : (
              <FA.FaTrash color='#FF3939' size={16}/>
            )}
          </Button>
        )}
      </Row>
      <AlertDialog
        visible={showAlertDialog}
        title='Atenção!'
        message='Deseja realmente excluir este serviço?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={deleteService}
        onClose={toggleAlertDialog}
        dangerous
      />
    </Container>
  )
}

export default BusinessService
