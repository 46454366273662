import React from 'react'
import ColumnChart from '../../../../components/Charts/ColumnChart'
import { BigInfoContainer, SubTitle } from '../../styles'

function AverageTickets ({ data: averageTickets }) {
  return (
    <BigInfoContainer>
      <SubTitle>Tickets médios</SubTitle>
      <ColumnChart
        data={averageTickets}
        options={{
          isStacked: false,
          height: 350,
          chartArea: {
            top: 30, left: 110, width: '64%', height: '75%'
          },
          vAxis: {
            title: 'Valor (R$)',
            minValue: 0,
            titleTextStyle: {
              bold: true
            }
          }
        }}
      />
    </BigInfoContainer>
  )
}

export default AverageTickets
