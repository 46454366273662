import React from 'react'
import { Chart } from 'react-google-charts'

function PieChart ({
  data,
  options = {},
  format = 'currency',
  ...props
}) {
  const chartOptions = {
    is3D: false,
    height: 220,
    colors: [
      '#FFC100', '#44CF6C', '#43D4CB', '#35A7FF', '#474AFD', '#B553DB', '#D164B8',
      '#FF3939', '#FF9939', '#96D35D', '#35FFBC', '#9335ff', '#FF65EA'
    ],
    backgroundColor: 'transparent',
    fontName: 'Segoe UI',
    pieSliceTextStyle: {
      bold: true
    },
    chartArea: {
      top: 10,
      bottom: 5,
      left: 0,
      right: 85
    },
    legend: {
      alignment: 'center',
      textStyle: {
        fontSize: 10
      }
    },
    tooltip: {
      text: 'percentage',
      textStyle: {
        fontSize: 14
      }
    },
    ...options
  }

  const formatEarningData = data => data.map ((item, index) => {
    if (index === 0) return item
    const formattedValue = item[1]?.toLocaleString ('pt-BR')
    return [`${item[0]} (${format === 'currency' ? 'R$ ' : ''}${formattedValue})`, item[1]]
  })

  return (
    <Chart
      chartType='PieChart'
      width='100%'
      data={formatEarningData (data)}
      options={chartOptions}
      {...props}
    />
  )
}

export default PieChart
