import React from 'react'
import { Chart } from 'react-google-charts'

function ColumnChart ({
  data,
  options = {},
  ...props
}) {
  const chartOptions = {
    isStacked: true,
    backgroundColor: 'transparent',
    height: 500,
    fontName: 'Segoe UI',
    hAxis: {
      title: 'Mês / ano',
      titleTextStyle: {
        bold: true
      }
    },
    vAxis: {
      title: 'Faturamento (R$)',
      titleTextStyle: {
        bold: true
      }
    },
    chartArea: {
      width: '60%',
      left: 110
    },
    colors: [
      '#FFC100', '#44CF6C', '#43D4CB', '#35A7FF', '#474AFD', '#B553DB', '#D164B8',
      '#FF3939', '#FF9939', '#96D35D', '#35FFBC', '#9335ff', '#FF65EA'
    ],
    tooltip: {
      textStyle: {
        fontSize: 14
      }
    },
    ...options
  }

  return (
    <Chart
      chartType='ColumnChart'
      width='100%'
      data={data}
      options={chartOptions}
      {...props}
    />
  )
}

export default ColumnChart
