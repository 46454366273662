import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  margin-top: 10px;
`

export const LabelRow = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  margin: 0px 30px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 7px;
`
