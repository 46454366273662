class Auth {
  static signIn (data) {
    localStorage.setItem ('token', data.token)
    if (data.business)
      localStorage.setItem ('business', JSON.stringify (data.business))
    else if (data.professional)
      localStorage.setItem ('professional', JSON.stringify (data.professional))
    else if (data.admin)
      localStorage.setItem ('admin', JSON.stringify (data.admin))
  }

  static businessSignOut () {
    if (!this.isAdmin ()) localStorage.removeItem ('token')
    localStorage.removeItem ('business')
    localStorage.removeItem ('professional')
  }

  static adminSignOut () {
    localStorage.removeItem ('token')
    localStorage.removeItem ('admin')
  }

  static requestAuth = {
    headers: {
      'Authorization': `Bearer ${this.getToken ()}`
    }
  }

  static getToken () {
    return localStorage.getItem ('token')
  }

  static isSigned () {
    return this.getToken () !== null
  }

  static isAdmin () {
    return localStorage.getItem ('admin') !== null
  }

  static isBusiness () {
    return localStorage.getItem ('business') !== null
  }

  static isProfessional () {
    return localStorage.getItem ('professional') !== null
  }

  static getBusinessId () {
    const user = this.getUser ()
    return this.isBusiness () ? user.id : user.business_id
  }

  static getUser () {
    if (this.isBusiness ())
      return JSON.parse (localStorage.getItem ('business'))
    if (this.isProfessional ())
      return JSON.parse (localStorage.getItem ('professional'))
    if (this.isAdmin ())
      return JSON.parse (localStorage.getItem ('admin'))
  }

  static accessBusiness (businessData) {
    if (!this.isAdmin ()) return
    localStorage.setItem ('business', JSON.stringify (businessData))
  }

  static updateUser (data) {
    if (data.business)
      localStorage.setItem ('business', JSON.stringify (data.business))
    else if (data.professional)
      localStorage.setItem ('professional', JSON.stringify (data.professional))
  }
}

export default Auth
