import React, { useState, useEffect } from 'react'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import CutileiReservationList from '../../../../components/Lists/CutileiReservationList'
import CreateCutileiReservationModal from '../../../Modals/CreateCutileiReservationModal'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  Container,
  Button,
  Row
} from '../../styles'

function EditCutileiReservations () {
  const [loading, setLoading] = useState (true)
  const [reservations, setReservations] = useState ([])
  const [showCreateReservationModal, setShowCreateReservationModal] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      setLoading (true)
      const { data: reservations } = await cutileiApi.get (
        `/businesses/${businessId}/cutilei_reservations`, requestConfig
      )
      setReservations (reservations)
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const compareReservations = (a, b) => (
    DateTime.fromISO (a.start_date).toMillis () - DateTime.fromISO (b.start_date).toMillis ()
  )

  const handleCreateReservation = reservation => {
    setReservations ([...reservations, reservation].sort (compareReservations))
  }

  const handleEditReservation = reservation => {
    setReservations (reservations.map (r => r.id === reservation.id ? {...reservation} : r))
  }

  const handleDeleteReservation = reservation => {
    setReservations (reservations.filter (r => r.id !== reservation.id))
  }

  const toggleCreateReservationModal = () => setShowCreateReservationModal (!showCreateReservationModal)

  return loading ? <Loading style={{marginTop: 30}}/> : (
    <Container style={{paddingTop: 10}}>
      <Row>
        <Button onClick={toggleCreateReservationModal}>
          Criar reserva
        </Button>
      </Row>
      {loading ? <Loading/> : (
        <CutileiReservationList
          reservations={reservations}
          onEdit={handleEditReservation}
          onDelete={handleDeleteReservation}
        />
      )}
      <CreateCutileiReservationModal
        visible={showCreateReservationModal}
        onClose={toggleCreateReservationModal}
        onConfirm={handleCreateReservation}
      />
    </Container>
  )
}

export default EditCutileiReservations
