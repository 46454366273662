import React, { useState, useCallback } from 'react'
import Modal from 'react-modal'
import Cropper from 'react-easy-crop'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import getCroppedImg from '../../../services/Image'
import FileInput from '../../../components/Inputs/FileInput'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Form,
  Title,
  Image,
  SmallImage,
  CropContainer,
  RangeInput,
  Button,
  DangerButton,
  CloseButton,
  InfoText,
  ButtonText,
  ControlsContainer,
  ErrorContainer
} from './styles'

function ChangePictureModal ({
  visible,
  title = 'Editar foto',
  onConfirm: handleConfirm,
  onClose
}) {
  const [loading, setLoading] = useState (false)
  const [imageSrc, setImageSrc] = useState (null)
  const [crop, setCrop] = useState ({ x: 0, y: 0 })
  const [zoom, setZoom] = useState (1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState (null)
  const [croppedImage, setCroppedImage] = useState (null)
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const business = AuthService.getUser ()

  const handleCropComplete = useCallback ((_, croppedAreaPixels) => {
    setCroppedAreaPixels (croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback (async () => {
    try {
      const croppedImage = await getCroppedImg (imageSrc, croppedAreaPixels)
      setCroppedImage (croppedImage)
    } catch (error) {
      console.log (error)
    }
  }, [imageSrc, croppedAreaPixels])

  const handleFileSelected = async file => {
    const fileUrl = await readFile (file)
    setImageSrc (fileUrl)
  }

  const readFile = file => {
    return new Promise (resolve => {
      const reader = new FileReader ()
      reader.addEventListener ('load', () => resolve (reader.result), false)
      reader.readAsDataURL (file)
    })
  }

  const handleSubmit = async () => {
    setLoading (true)
    try {
      const formData = new FormData ()

      formData.append ('profile_picture', croppedImage.file, croppedImage.file.name)

      const { data: fileUri } = await cutileiApi.put (
        `/businesses/${businessId}/profile_picture`, 
        formData, 
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      
      setLoading (false)
      handleConfirm (fileUri)
    } catch (error) {
      setLoading (false)
      console.log (error)
    }
  }

  const handleClose = () => {
    setImageSrc (null)
    setCroppedAreaPixels (null)
    setCroppedImage (null)
    onClose ()
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Form>
        <Title>{title}</Title>
        <CloseButton onClick={handleClose}>
          <FA.FaTimes color='#FF3939' size={18}/>
        </CloseButton>
        <InfoText>Foto atual</InfoText>
        <SmallImage src={business.logo} alt='logo'/>
        {!croppedImage && (
          <FileInput
            label={imageSrc ? 'Trocar imagem' : 'Selecionar imagem'}
            onFileSelected={handleFileSelected}
          />
        )}
        {imageSrc && !croppedImage && (
          <Container>
            <InfoText style={{marginTop: 10, marginBottom: 0}}>
              Recorte a imagem
            </InfoText>
            <CropContainer>
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={4 / 4}
                onCropChange={setCrop}
                onCropComplete={handleCropComplete}
                onZoomChange={setZoom}
              />
            </CropContainer>
            <ControlsContainer>
              <InfoText>Zoom</InfoText>
              <RangeInput
                value={zoom}
                aria-labelledby='Zoom'
                onChange={(e) => {
                  setZoom (e.target.value)
                }}
              />
            </ControlsContainer>
            <Button onClick={showCroppedImage}>
              Recortar e prosseguir
            </Button>
          </Container>
        )}
        {croppedImage && (
          <Container>
            <Image src={croppedImage.url}/>
            {errorMessage && (
              <ErrorContainer>
                <ButtonText>{errorMessage}</ButtonText>
              </ErrorContainer>
            )}
            <Button onClick={handleSubmit} disabled={loading}>
              {loading ? <ButtonLoading/> : 'Confirmar alteração'}
            </Button>
            <DangerButton onClick={() => setCroppedImage (null)}>
              Descartar imagem
            </DangerButton>
          </Container>
        )}
      </Form>
    </Modal>
  )
}

export default ChangePictureModal
