import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import General from '../../../services/General'
import MaskedInputService from '../../../services/maskedInput'
import { Formik } from 'formik'
import customerValidator from '../../../validators/customerValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  MaskedInput,
  Button,
  CloseButton,
  Row,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddCustomerModal ({
  visible,
  query = '',
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()
  const queryType = General.getStringType (query)

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={customerValidator}
        initialValues={{
          'name': queryType === 'text' ? query : '',
          'email': queryType === 'email' ? query : '',
          'phone': queryType === 'phone' ? query : ''
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { data: customer } = await cutileiApi.post (
              `/businesses/${businessId}/customers`, values, requestConfig
            )
            
            setSubmitting (false)
            handleConfirm ({...customer, balance: 0})
            handleClose ()
          } catch (error) {
            setSubmitting (false)
            if (error.response.data) setErrorMessage (error.response.data.message)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Cadastrar Cliente</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField>
                <Input 
                  placeholder='Nome'
                  value={values.name}
                  onChange={handleChange ('name')} 
                  onBlur={handleBlur ('name')}
                />
                {touched.name && errors.name && (
                  <WarningText>
                    {errors.name}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <Input
                  type='email'
                  placeholder='E-mail'
                  value={values.email}
                  onChange={handleChange ('email')}
                  onBlur={handleBlur ('email')}
                />
                {touched.email && errors.email && (
                  <WarningText>
                    {errors.email}
                  </WarningText>
                )}
              </FormField>
            </Row>
            <Row>
              <FormField>
                <MaskedInput
                  name='phone'
                  placeholder='Celular'
                  options={{
                    blocks: [0, 2, 5, 4],
                    delimiters: ['(', ') ', '-'],
                    numericOnly: true,
                  }}
                  value={values.phone}
                  onChange={event => MaskedInputService.handleChange (event, setFieldValue)}
                  onBlur={handleBlur ('phone')}
                />
                {touched.phone && errors.phone && (
                  <WarningText>
                    {errors.phone}
                  </WarningText>
                )}
              </FormField>
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Cadastrar'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddCustomerModal
