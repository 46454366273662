import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import Phone from '../../../services/Phone'
import RadioButton from '../../../components/RadioButton'
import AlertDialog from '../../../components/AlertDialog'
import OptionDialog from '../../../components/OptionDialog'
import EditCustomerModal from '../EditCustomerModal'
import CustomerModal from '../CustomerModal'
import EditScheduleForm from '../../../components/Forms/EditScheduleForm'
import Logo from '../../../icons/logo-cutilei-simple.png'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import ReactTooltip from 'react-tooltip'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Container,
  FormField,
  Row,
  Input,
  Image,
  Title,
  SubTitle,
  InfoText,
  ButtonText,
  Label,
  Button,
  CustomerButton,
  AltButton,
  CloseButton,
  DangerButton,
  StatusGrid,
  StatusBlock,
  BottomRow,
  LoadingBackdrop,
  ErrorContainer
} from './styles'

function ScheduleDetailsModal ({
  visible,
  schedules = [],
  data: schedule,
  onEdit: handleEdit,
  onDelete: handleDelete,
  onDeleteAll: handleDeleteAll,
  onEditStatus: handleEditStatus,
  onClose: handleClose,
  onAddSchedule,
  onOpenBill,
  onEditBill
}) {
  const scheduleDateTime = DateTime.fromFormat (`${schedule.date} ${schedule.time}`, 'yyyy-MM-dd H:mm')
  const isCompleted = DateTime.now () >= scheduleDateTime?.plus ({minutes: schedule.max_delay + 1})
  const customerSchedules = schedules.filter (s => (
    s.validated !== undefined && s.id !== schedule.id
    && (s.customer?.id === schedule.customer?.id && s.member?.id === schedule.member?.id) 
  ))
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const statusList = [
    {codename: 'scheduled', label: 'Agendado', color: '#252525', disabled: schedule.canceled},
    {codename: 'confirmed', label: 'Confirmado', color: '#89C6C2', disabled: schedule.canceled},
    {codename: 'waiting', label: 'Aguardando', color: '#FFD860', disabled: schedule.canceled},
    {codename: 'in_progress', label: 'Em atendimento', color: '#ACD685', disabled: schedule.canceled},
    {codename: 'finished', label: 'Finalizado', color: '#D39CC6', disabled: schedule.canceled},
    {codename: 'paid', label: 'Pago', color: '#52A067', disabled: true},
    {codename: 'missed', label: 'Cliente faltou', color: '#DF6969', disabled: schedule.canceled},
    {codename: 'canceled', label: 'Cancelado', color: '#FF3939', disabled: schedule.canceled}
  ]

  const statusForAllSchedules = ['confirmed', 'waiting', 'confirmed', 'in_progress']
  const [loadingBill, setLoadingBill] = useState (false)
  const [submitting, setSubmitting] = useState (false)
  const [deleting, setDeleting] = useState (false)
  const [customer, setCustomer] = useState (schedule.customer)
  const [errorMessage, setErrorMessage] = useState (null)
  const [showEditCustomerModal, setShowEditCustomerModal] = useState (false)
  const [showCustomerDetailsModal, setShowCustomerDetailsModal] = useState (false)
  const [showEditStatusDialog, setShowEditStatusDialog] = useState (false)
  const [showEditAllStatusDialog, setShowEditAllStatusDialog] = useState (false)
  const [showDeleteDialog, setShowDeleteDialog] = useState (false)
  const [showInactivateDialog, setShowInactivateDialog] = useState (false)
  const [selectedStatus, setSelectedStatus] = useState ({
    codename: '', label: '', color: ''
  })
  const [status, setStatus] = useState (
    schedule.validated
      ? 'paid' 
      : schedule.cutilei_schedule && isCompleted
        ? 'missed' 
        : schedule.status
  )

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    setCustomer (schedule.customer)
    setErrorMessage (null)
  }, [schedule])

  useEffect (() => {
    setStatus (schedule.status)
  }, [schedule.status])

  const handleOpenBill = async () => {
    try {
      setLoadingBill (true)
      const customer = await getCustomer ()
      const status = 'waiting'
      const editedSchedules = []

      const { data: bill } = await cutileiApi.post ('/business_bills', {
        businessId,
        customerId: customer.id,
        scheduleId: schedule.id
      }, requestConfig)

      await Promise.all (
        [...customerSchedules, schedule].map (async schedule => {
          if (['scheduled', 'confirmed'].includes (schedule.status)) {
            await cutileiApi.post (
              `/schedules/${schedule.id}/status`, {status}, requestConfig
            )
            editedSchedules.push ({...schedule, status, bill_id: bill.id})
          }
        })
      )

      setLoadingBill (false)
      onOpenBill (bill)
      handleEditStatus (editedSchedules)
    } catch (error) {
      setLoadingBill (false)
      if (error.response?.data) setErrorMessage (error.response.data.message)
      console.log (error)
    }
  }

  const handleEditBill = async () => {
    try {
      setLoadingBill (true)

      const { data: bill } = await cutileiApi.get (
        `/business_bills/${schedule.bill_id}`, requestConfig
      )

      setLoadingBill (false)
      onEditBill (bill)
    } catch (error) {
      setLoadingBill (false)
      console.log (error.response.data)
    }
  }

  const handleStatusChange = status => {
    setSelectedStatus (status)
    if (statusForAllSchedules.includes (status.codename) && customerSchedules.length > 0)
      toggleEditAllStatusDialog ()
    else toggleEditStatusDialog ()
  }

  const changeScheduleStatus = async (status, changeAllSchedules = false) => {
    const editedSchedules = []
    try {
      setSubmitting (true)

      await cutileiApi.post (`/schedules/${schedule.id}/status`, {status}, requestConfig)
      setStatus (status)
      editedSchedules.push ({...schedule, status})

      if (changeAllSchedules) await Promise.all (
        customerSchedules.map (async schedule => {
          await cutileiApi.post (`/schedules/${schedule.id}/status`, {status}, requestConfig)
          editedSchedules.push ({...schedule, status})
        })
      )

      handleEditStatus (editedSchedules)
    } catch (error) {
      console.log (error)
      setErrorMessage (error.response?.data)
    } finally {
      setSubmitting (false)
    }
  }

  const cancelSchedule = async () => {
    try {
      setSubmitting (true)
      await cutileiApi.delete (`/schedules/${schedule.id}`, requestConfig)
      handleDelete (schedule)
      handleClose ()
    } catch (error) {
      console.log (error)
      setErrorMessage (error.response?.data)
    } finally {
      setSubmitting (false)
    }
  }

  const cancelAllSchedules = async () => {
    const canceledSchedules = []
    try {
      setSubmitting (true)

      await Promise.all (
        [...customerSchedules, schedule].map (async schedule => {
          await cutileiApi.delete (`/schedules/${schedule.id}`, requestConfig)
          canceledSchedules.push (schedule)
        })
      )
      handleDeleteAll (canceledSchedules)
      handleClose ()
    } catch (error) {
      console.log (error)
      setErrorMessage (error.response?.data)
    } finally {
      setSubmitting (false)
    }
  }

  const inactivateSchedule = async () => {
    try {
      setDeleting (true)
      await cutileiApi.put (`/schedules/${schedule.id}/activation`, {
        active: false
      }, requestConfig)

      handleDelete (schedule)
      handleClose ()
    } catch (error) {
      console.log (error)
      setErrorMessage (error.response?.data)
    } finally {
      setDeleting (false)
    }
  }

  const handleAddSchedule = async () => {
    const customer = await getCustomer ()
    onAddSchedule (customer)
  }

  const getCustomer = async () => {
    let customer = schedule.customer

    if (schedule.member && !customer) {
      const { data: customerData } = await cutileiApi.post (`/businesses/${businessId}/customers`, {
        name: schedule.member.name,
        email: '',
        phone: ''
      }, requestConfig)

      customer = customerData
    }

    return customer
  }

  const toggleEditCustomerModal = () => setShowEditCustomerModal (!showEditCustomerModal)
  const toggleCustomerDetailsModal = () => setShowCustomerDetailsModal (!showCustomerDetailsModal)
  const toggleEditStatusDialog = () => setShowEditStatusDialog (!showEditStatusDialog)
  const toggleEditAllStatusDialog = () => setShowEditAllStatusDialog (!showEditAllStatusDialog)
  const toggleDeleteDialog = () => setShowDeleteDialog (!showDeleteDialog)
  const toggleInactivateDialog = () => setShowInactivateDialog (!showInactivateDialog)

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container>
        <Title>Detalhes do agendamento</Title>
        <CloseButton onClick={handleClose}>
          <FA.FaTimes color='#FF3939' size={18}/>
        </CloseButton>
        <Row>
          {customer ? (
            <>
              <FormField>
                <Label>Cliente</Label>
                <CustomerButton onClick={toggleCustomerDetailsModal}>
                  <InfoText>
                    {customer.name + (customer.phone ? ` - ${Phone.formatPhone (customer.phone)}`: '')}
                  </InfoText>
                </CustomerButton>
              </FormField>
              <AltButton onClick={toggleEditCustomerModal} style={{marginLeft: 10}}>
                Alterar dados
              </AltButton>
            </>
          ) : (
            <FormField>
              <Label>Cliente</Label>
              <Input type='text' value={schedule.member?.name} disabled/>
            </FormField>
          )}
        </Row>
        <EditScheduleForm data={schedule} onEdit={handleEdit}/>
        <Row style={{margin: 0}}>
          {schedule.cutilei_schedule && schedule.canceled && (
            <Row style={{gap: 7, alignItems: 'center', marginLeft: 5}}>
              <FA.FaTimesCircle color='#FF3939' size={17}/>
              <SubTitle style={{color: '#FF3939'}}>
                Agendamento cancelado
              </SubTitle>
            </Row>
          )}
          {schedule.cutilei_schedule && (
            <Row style={{margin: 0, paddingInline: 7, justifyContent: 'flex-end'}}>
              <Image src={Logo} alt='Agendamento Cutilei'/>
              <SubTitle>Agendamento Cutilei</SubTitle>
            </Row>
          )}
        </Row>
        {!schedule.cutilei_schedule && (
          <>
            <SubTitle>Status</SubTitle>
            <StatusGrid>
              {submitting && (
                <LoadingBackdrop>
                  <Loading/>
                </LoadingBackdrop>
              )}
              {statusList.map (statusOption => (
                <StatusBlock
                  key={statusOption.codename}
                  color={statusOption.color}
                  disabled={statusOption.disabled}
                  onClick={statusOption.disabled ? null : () => {
                    if (statusOption.codename === 'canceled') toggleDeleteDialog ()
                    else handleStatusChange (statusOption)
                  }}
                  data-for={statusOption.codename}
                  data-tip
                >
                  <RadioButton
                    circleColor='#FFFFFF'
                    dotColor='#FFFFFF'
                    value={status === statusOption.codename}
                    disabled={statusOption.disabled}
                    onValueChange={(_) => {
                      if (statusOption.codename === 'canceled') toggleDeleteDialog ()
                      else handleStatusChange (statusOption)
                    }}
                  />
                  {statusOption.label}
                </StatusBlock>
              ))}
              <ReactTooltip id='paid' effect='solid' place='bottom' backgroundColor='#252525' tooltipRadius='10'>
                <Label style={{color: '#FFFFFF'}}>
                  Para salvar o agendamento como pago, abra e finalize a comanda dele
                </Label>
              </ReactTooltip>
            </StatusGrid>
          </>
        )}
        <AltButton style={{alignSelf: 'center'}} onClick={handleAddSchedule}>
          <FA.FaPlus size={12} style={{marginRight: 7, marginBottom: 2}}/>
          Adicionar agendamento
        </AltButton>
        {errorMessage && (
          <ErrorContainer>
            <ButtonText>{errorMessage}</ButtonText>
          </ErrorContainer>
        )}
        <BottomRow>
          <Button
            type='button'
            onClick={schedule.bill_id ? handleEditBill : handleOpenBill}
            style={{marginTop: 20}}
          >
            {loadingBill ? <ButtonLoading/> : `${schedule.bill_id ? 'Exibir' : 'Abrir'} comanda`}
          </Button>
          {schedule.cutilei_schedule && isCompleted && !schedule.validated && schedule.active && (
            <DangerButton type='button' onClick={toggleInactivateDialog}>
              {deleting ? <ButtonLoading/> : 'Remover da agenda'}
            </DangerButton>
          )}
          {customer && (
            <>
              <EditCustomerModal
                visible={showEditCustomerModal}
                data={customer}
                onConfirm={setCustomer}
                onClose={toggleEditCustomerModal}
              />
              <CustomerModal
                visible={showCustomerDetailsModal}
                data={customer}
                onClose={toggleCustomerDetailsModal}
              />
            </>
          )}
        </BottomRow>
      </Container>
      <AlertDialog
        visible={showEditStatusDialog}
        title='Atenção!'
        message={<>
          {`Deseja realmente alterar o status este agendamento para `}
          <b style={{color: selectedStatus.color}}>{selectedStatus.label}?</b>
        </>}
        confirmText='Sim'
        cancelText='Não'
        onConfirm={() => changeScheduleStatus (selectedStatus.codename, false)}
        onClose={toggleEditStatusDialog}
      />
      <OptionDialog
        visible={showEditAllStatusDialog}
        title='Atenção!'
        message={
          <InfoText>
            {`Deseja alterar o status de todos os agendamentos deste cliente para `}
            <b style={{color: selectedStatus.color}}>{selectedStatus.label}</b>
            {` ou alterar apenas este agendamento?`}
          </InfoText>
        }
        options={[
          {
            label: 'Alterar apenas este',
            onClick: () => changeScheduleStatus (selectedStatus.codename, false),
            dangerous: false
          },
          {
            label: 'Alterar todos',
            onClick: () => changeScheduleStatus (selectedStatus.codename, true),
            dangerous: false
          }
        ]}
        onClose={toggleEditAllStatusDialog}
        containerStyle={{width: 430}}
        buttonStyle={{width: 160}}
      />
      <OptionDialog
        visible={showDeleteDialog}
        title='Atenção!'
        message={
          customerSchedules.length > 0
            ?'Deseja cancelar todos os agendamentos deste cliente ou apenas este agendamento?'
            :'Deseja realmente cancelar este agendamento?'
        }
        options={customerSchedules.length > 0 
          ? [
            {label: 'Cancelar apenas este', onClick: cancelSchedule, dangerous: false},
            {label: 'Cancelar todos', onClick: cancelAllSchedules, dangerous: true}
          ] : [
            {label: 'Sim', onClick: cancelSchedule, dangerous: true},
            {label: 'Não', onClick: toggleDeleteDialog, dangerous: false}
          ]
        }
        onClose={toggleDeleteDialog}
        containerStyle={customerSchedules.length > 0 ? {width: 430} : {}}
        buttonStyle={customerSchedules.length > 0 ? {width: 180} : {}}
      />
      <AlertDialog
        visible={showInactivateDialog}
        title='Atenção!'
        message='Deseja realmente remover este agendamento da agenda?'
        confirmText='Sim'
        cancelText='Não'
        onConfirm={inactivateSchedule}
        onClose={toggleInactivateDialog}
        dangerous
      />
    </Modal>
  )
}

export default ScheduleDetailsModal
