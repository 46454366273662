import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import Comission from '../../../services/Comission'
import Dashboard from '../../../services/Dashboard'
import General from '../../../services/General'
import Export from '../../../services/Export'
import Navbar from '../../../components/Navbar'
import Select from '../../../components/Inputs/Select'
import DateRangeInput from '../../../components/Inputs/DateRangeInput'
import AddProfessionalCreditModal from '../../Modals/AddProfessionalCreditModal'
import AddProfessionalDebtModal from '../../Modals/AddProfessionalDebtModal'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'
import ExcelJS from 'exceljs'
import '../../../styles/calendar.css'

import {
  ListContainer,
  DayContainer,
  FilterContainer,
  Row,
  LabelRow,
  SubTitle,
  Button,
  MoneyTextMask
} from '../styles'

function Comissions () {
  const startDate = localStorage.getItem ('financial_start_date')
  const endDate = localStorage.getItem ('financial_end_date')
  const [loading, setLoading] = useState (true)
  const [showAddCreditModal, setShowAddCreditModal] = useState (false)
  const [showAddDebtModal, setShowAddDebtModal] = useState (false)
  const [professionals, setProfessionals] = useState ([])
  const [categories, setCategories] = useState ([])
  const [category, setCategory] = useState (null)
  const [earningsByPeriod, setEarningsByPeriod] = useState ([])
  const [earningsByDay, setEarningsByDay] = useState ([])
  const [sortedEarnings, setSortedEarnings] = useState ([])
  const [bonuses, setBonuses] = useState ([])
  const [discounts, setDiscounts] = useState ([])
  const [bonusTotal, setBonusTotal] = useState (0)
  const [discountTotal, setDiscountTotal] = useState (0)
  const [selectedProfessional, setSelectedProfessional] = useState (
    JSON.parse (localStorage.getItem ('financial_selected_professional')) ?? {
      label: 'Todos os profissionais', value: 'all_professionals'
    }
  )
  const [earningType, setEarningType] = useState (
    JSON.parse (localStorage.getItem ('financial_earning_type')) ?? {
      label: 'Todos os rendimentos', value: 'all_earnings'
    }
  )
  const [dateRange, setDateRange] = useState (startDate && endDate ? [
    DateTime.fromISO (startDate).toJSDate (),
    DateTime.fromISO (endDate).toJSDate ()
  ] : [
    DateTime.now ().startOf ('month').toJSDate (),
    DateTime.now ().toJSDate ()
  ])
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const professionalLabel = selectedProfessional.value === 'all_professionals'
    ? 'dos profissionais' : 'do profissional'

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  useEffect (() => {
    getEarningsByPeriod ()
  }, [dateRange, selectedProfessional, earningType, category])

  useEffect (() => {
    getSortedEarnings ()
  }, [earningsByPeriod])

  const getData = async () => {
    try {
      setLoading (true)

      const { data: categories } = await cutileiApi.get ('/service_categories', requestConfig)
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals?access_level=service`,
        requestConfig
      )

      setCategories (categories.map (category => ({
        label: category.name,
        value: category.id
      })))
      
      setProfessionals (professionals)
      await getEarningsByPeriod ()
    } catch (error) {
      console.log (error.response.data)
    } finally {
      setLoading (false)
    }
  }

  const getEarningsByPeriod = async () => {
    const startDate = dateRange ? DateTime.fromJSDate (dateRange[0]).toISODate () : null
    const endDate = dateRange ? DateTime.fromJSDate (dateRange[1]).toISODate () : null
    let earnings = []
    let filters = `?business_id=${businessId}&order_by=created_at&order=desc`
                + '&detailed=true&open=false&status=validated&deleted=false&page=1&per_page=999999'
    const dateFilters = `&start_date=${startDate}&end_date=${endDate}`

    if (startDate && endDate) filters += dateFilters
    if (['cutilei', 'all_earnings'].includes (earningType.value))
      filters += '&cutilei_schedules=true'
    if (category) filters += `&service_category_id=${category.value}`
    if (selectedProfessional.value !== 'all_professionals')
      filters += `&professional_id=${selectedProfessional.value}`

    try {
      setLoading (true)
      setBonuses ([])
      setDiscounts ([])
        
      if (['cutilei', 'all_earnings'].includes (earningType.value)) {
        const { data: schedules } = await cutileiApi.get (`/schedules${filters}`, requestConfig)
        earnings = [...earnings, ...schedules.data.map (s => ({...s, type: 'cutilei'}))]
      }

      if (['bill', 'all_earnings'].includes (earningType.value)) {
        const { data: bills } = await cutileiApi.get (`/business_bills${filters}`, requestConfig)
        earnings = [...earnings, ...bills.map (b => ({...b, type: 'bill'}))]
      }

      if (selectedProfessional.value !== 'all_professionals' && earningType.value === 'all_earnings') {
        const { data: bonuses } = await cutileiApi.get (
          `/businesses/${businessId}/credits?professional_id=${selectedProfessional.value}`
          + dateFilters,
          requestConfig
        )
        
        const { data: discounts } = await cutileiApi.get (
          `/businesses/${businessId}/debts?professional_id=${selectedProfessional.value}`
          + dateFilters,
          requestConfig
        )

        earnings = [...earnings, ...bonuses.map (b => ({...b, type: 'bonus'}))]
        earnings = [...earnings, ...discounts.map (d => ({
          ...d, value: d.value * -1, type: 'discount'
        }))]
        setBonuses (bonuses)
        setDiscounts (discounts)
        setBonusTotal (bonuses.reduce ((total, bonus) => total + bonus.value, 0))
        setDiscountTotal (discounts.reduce ((total, bonus) => total + bonus.value * -1, 0))
      }

      earnings = earnings.sort (orderEarningsByDate)
      setEarningsByPeriod (earnings)
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const getSortedEarnings = () => {
    const earningsByDay = General.groupBy (earningsByPeriod, 'date')
    const totalEarnings = Comission.getTotalEarnings (earningsByPeriod, earningType, category)
    const businessEarnings = Comission.getBusinessEarnings (earningsByPeriod, earningType, category)
    const professionalEarnings = Comission.getProfessionalEarnings (earningsByPeriod, earningType, category)

    const sortedEarnings = [{
      day: 'TOTAL DO PERÍODO',
      totalEarnings,
      professionalEarnings,
      ...bonuses.length > 0 && {bonusTotal},
      ...discounts.length > 0 && {discountTotal},
      professionalTotalEarnings: professionalEarnings + bonusTotal + discountTotal,
      businessEarnings
    }].concat (earningsByDay.map (earnings => {
      const day = DateTime.fromISO (earnings[0].date).toFormat ('dd/MM/yyyy')
      const dayBonuses = earnings.filter (e => e.type === 'bonus')
      const dayDiscounts = earnings.filter (e => e.type === 'discount')
      const dayBonusTotal = dayBonuses.reduce ((total, bonus) => total + bonus.value, 0)
      const dayDiscountTotal = dayDiscounts.reduce ((total, discount) => total + discount.value, 0)
      const totalDayEarnings = Comission.getTotalEarnings (earnings, earningType, category)
      const professionalDayEarnings = Comission.getProfessionalEarnings (earnings, earningType, category)
      const professionalTotalDayEarnings = professionalDayEarnings + dayBonusTotal + dayDiscountTotal
      const businessDayEarnings = Comission.getBusinessEarnings (earnings, earningType, category)

      return ({
        day,
        totalEarnings: totalDayEarnings,
        professionalEarnings: professionalDayEarnings,
        ...bonuses.length > 0 && {bonusTotal: dayBonusTotal},
        ...discounts.length > 0 && {discountTotal: dayDiscountTotal},
        professionalTotalEarnings: professionalTotalDayEarnings,
        businessEarnings: businessDayEarnings
      })
    }))

    setEarningsByDay (earningsByDay)
    setSortedEarnings (sortedEarnings)
  }

  const handleExportSheet = () => {
    const workbook = new ExcelJS.Workbook ()
    const title = 'Rendimentos do periodo'
    const worksheet = workbook.addWorksheet (title)
    const startDate = DateTime.fromJSDate (dateRange[0]).toFormat ('dd/MM/yyyy')
    const endDate = DateTime.fromJSDate (dateRange[1]).toFormat ('dd/MM/yyyy')
    const description = `(${startDate} a ${endDate}) - ${selectedProfessional.label}`
    const fileName = `${title} ${description.replace (/\//g, '-')}`

    worksheet.columns = [
      {header: description, key: 'day', width: description.length + 2},
      {header: 'Total bruto', key: 'totalEarnings', width: 25},
      {header: 'Comissões', key: 'professionalEarnings', width: 25},
      bonuses.length > 0 ? {header: 'Bônus', key: 'bonusTotal', width: 25} : null,
      discounts.length > 0 ? {header: 'Descontos', key: 'discountTotal', width: 25} : null,
      {header: `Total ${professionalLabel}`, key: 'professionalTotalEarnings', width: 25},
      {header: 'Total Líquido', key: 'businessEarnings', width: 25}
    ].filter (o => o)

    worksheet.addRows (sortedEarnings)

    worksheet.eachRow ((row, rowNumber) => {
      row.height = rowNumber > 1 ? 20 : 30
      row.eachCell ((cell, colNumber) => {
        cell.font = {name: 'Arial', bold: true, color: {argb: rowNumber > 1 ? '000000' : 'FFFFFF'}}
        cell.alignment = {vertical: 'middle', horizontal: 'center'}
        if (colNumber > 1) {
          cell.numFmt = '"R$ "#,##0.00;"R$ "#,##0.00'
          if (rowNumber > 1) cell.font.color = {argb: cell.value >= 0 ? '44CF6C' : 'FF3939'}
        }
        if (rowNumber > 1) cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'EEEEEE'}}
        else cell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFC100'}}
      })
    })
    
    Export.downloadWorkSheet (workbook, fileName)
  }

  const orderEarningsByDate = (a, b) => (
    DateTime.fromISO (b.date).toMillis () - DateTime.fromISO (a.date).toMillis ()
  )

  const toggleAddCreditModal = () => setShowAddCreditModal (!showAddCreditModal)
  const toggleAddDebtModal = () => setShowAddDebtModal (!showAddDebtModal)

  return (
    <ListContainer>
      <Navbar/>
      <FilterContainer>
        <DateRangeInput
          value={dateRange}
          onChange={dateRange => {
            setDateRange (dateRange)
            localStorage.setItem ('financial_start_date', DateTime.fromJSDate (dateRange[0]).toISODate ())
            localStorage.setItem ('financial_end_date', DateTime.fromJSDate (dateRange[1]).toISODate ())
          }}
        />
        <Select
          name='scheduleType'
          placeholder='Tipo de agendamento...'
          value={earningType}
          options={Dashboard.TYPE_FILTERS}
          onChange={(_, option) => {
            setEarningType (option)
            localStorage.setItem ('financial_earning_type', JSON.stringify (option))
          }}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15}}
        />
        <Select
          name='professional'
          placeholder='Profissional...'
          value={selectedProfessional}
          options={[
            {label: 'Todos os profissionais', value: 'all_professionals'},
            ...professionals.map (professional => ({
              label: professional.nickname ? professional.nickname : professional.name,
              value: professional.id,
            }))
          ]}
          onChange={(_, option) => {
            setSelectedProfessional (option)
            localStorage.setItem ('financial_selected_professional', JSON.stringify (option))
          }}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15}}
        />
        <Select
          name='category'
          placeholder='Categoria de serviço...'
          value={category}
          options={categories}
          isClearable={true}
          onChange={(_, option) => setCategory (option)}
          containerStyles={{boxShadow: '0px 1px 10px -7px', borderRadius: 15, height: 38}}
        />
      </FilterContainer>
      {loading ? <Loading style={{marginTop: 20}}/> : (
        <>
          <Row>
            <Button onClick={toggleAddCreditModal} style={{width: 120}}>
              <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
              Bônus
            </Button>
            <Button onClick={toggleAddDebtModal} style={{width: 120}}>
              <FA.FaPlus size={12} style={{marginTop: 2, marginRight: 7}}/>
              Desconto
            </Button>
            <Button onClick={handleExportSheet} style={{width: 120}}>
              <FA.FaFileExcel size={14} style={{marginTop: 2, marginRight: 7}}/>
              Exportar
            </Button>
          </Row>
          <ListContainer>
            <LabelRow bonuses={bonuses} discounts={discounts}>
              <SubTitle>Dia</SubTitle>
              <SubTitle>Total bruto</SubTitle>
              <SubTitle>Comissões</SubTitle>
              {bonuses.length > 0 && <SubTitle>Bônus</SubTitle>}
              {discounts.length > 0 && <SubTitle>Descontos</SubTitle>}
              <SubTitle>Total {professionalLabel}</SubTitle>
              <SubTitle>Total Líquido</SubTitle>
            </LabelRow>
            {sortedEarnings.map ((earning, index) => {
              const {
                day, totalEarnings, businessEarnings, professionalEarnings,
                bonusTotal, discountTotal, professionalTotalEarnings
              } = earning

              return (
                <DayContainer
                  key={index}
                  bonuses={bonuses}
                  discounts={discounts}
                  to={index === 0 ? {
                    pathname: '/business/financial/total',
                    state: {
                      dateRange,
                      earningType,
                      category,
                      professionals,
                      selectedProfessional,
                      totalEarnings,
                      businessEarnings,
                      professionalEarnings,
                      earningsByPeriod
                    }
                  } : {
                    pathname: '/business/financial/daily',
                    search: `?day=${day}`,
                    state: {
                      day: day,
                      dayEarnings: earningsByDay[index -1],
                      earningType,
                      category,
                      selectedProfessional
                    }
                  }
                }>
                  <SubTitle>{earning.day}</SubTitle>
                  <MoneyTextMask value={totalEarnings}/>
                  <MoneyTextMask value={professionalEarnings}/>
                  {bonuses.length > 0 && <MoneyTextMask value={bonusTotal}/>}
                  {discounts.length > 0 &&<MoneyTextMask value={discountTotal}/>}
                  <MoneyTextMask value={professionalTotalEarnings}/>
                  <MoneyTextMask value={businessEarnings}/>
                  <FA.FaChevronRight size={15} color='#252525'/>
                </DayContainer>
              )
            })}
          </ListContainer>
        </>
      )}
      <AddProfessionalCreditModal
        visible={showAddCreditModal}
        professionals={professionals}
        onConfirm={getEarningsByPeriod}
        onClose={toggleAddCreditModal}
      />
      <AddProfessionalDebtModal
        visible={showAddDebtModal}
        professionals={professionals}
        onConfirm={getEarningsByPeriod}
        onClose={toggleAddDebtModal}
      />
    </ListContainer>
  )
}

export default withRouter (Comissions)
