import React, { useState } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import SelectAsync from '../../../components/Inputs/SelectAsync'
import { Formik } from 'formik'
import billValidator from '../../../validators/billValidator'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Form,
  FormField,
  Title,
  Input,
  Button,
  CloseButton,
  Row,
  Label,
  InfoText,
  WarningText,
  ErrorContainer
} from './styles'

function AddBillModal ({
  visible,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [errorMessage, setErrorMessage] = useState (null)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const getCustomers = async query => {
    try {
      const { data: customers } = await cutileiApi.get (
        `/businesses/${businessId}/customers?query=${query}`, requestConfig
      )

      return customers.data.map (customer => ({
        label: customer.name,
        value: customer.id
      }))
    } catch (error) {
      console.log (error)
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Formik
        validationSchema={billValidator}
        initialValues={{
          'customer': null,
          'date': DateTime.now ().toFormat ('yyyy-MM-dd')
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { data: bill } = await cutileiApi.post ('/business_bills', {
              ...values,
              businessId,
              customerId: values.customer.value,
              value: 0
            }, requestConfig)

            setSubmitting (false)
            handleConfirm (bill)
            handleClose ()
          } catch (error) {
            if (error.response?.data) setErrorMessage (error.response.data.message)
            setSubmitting (false)
            console.log (error)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Title>Nova comanda</Title>
            <CloseButton onClick={handleClose}>
              <FA.FaTimes color='#FF3939' size={18}/>
            </CloseButton>
            <Row>
              <FormField>
                <Label>Cliente</Label>
                <SelectAsync
                  name='customer'
                  placeholder='Cliente...'
                  value={values.customer}
                  loadOptions={getCustomers}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  error={errors.customer}
                  touched={touched.customer}
                  DropdownIndicator={() => <FA.FaSearch style={{marginRight: 12}}/>}
                  onMenuOpen={() => setFieldValue ('customer', null)}
                />
                {touched.customer && errors.customer && (
                  <WarningText>
                    {errors.customer}
                  </WarningText>
                )}
              </FormField>
              <FormField>
                <Label>Data de abertura</Label>
                <Input 
                  type='date'
                  placeholder='Data da comanda'
                  value={values.date}
                  onChange={handleChange ('date')} 
                  onBlur={handleBlur ('date')}
                />
                {touched.date && errors.date && (
                  <WarningText>
                    {errors.date}
                  </WarningText>
                )}
              </FormField>
            </Row>
            {errorMessage && (
              <ErrorContainer>
                <InfoText>{errorMessage}</InfoText>
              </ErrorContainer>
            )}
            <Button type='submit' disabled={isSubmitting}>
              {isSubmitting ? <ButtonLoading/> : 'Abrir comanda'}
            </Button>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AddBillModal
