import React, { useState, useEffect } from 'react'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import Dashboard from '../../../services/Dashboard'
import Select from '../../../components/Inputs/Select'
import AverageTickets from './AverageTickets'
import SchedulesByStatus from './SchedulesByStatus'
import NewCustomers from './NewCustomers'
import SoldItems from './SoldItems'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { DateTime } from 'luxon'

import {
  selectStyle,
  DashboardContainer,
  LoadingContainer,
  FilterContainer,
  Title,
  InfoText
} from '../styles'

function Sales () {
  const now = DateTime.now ().setLocale ('pt-BR')
  const [loading, setLoading] = useState (true)
  const periodFilterOptions = Dashboard.PERIOD_FILTERS
  const typeFilterOptions = Dashboard.TYPE_FILTERS
  const [periodFilter, setPeriodFilter] = useState (periodFilterOptions[0])
  const [typeFilter, setTypeFilter] = useState (typeFilterOptions[0])
  const [averageTickets, setAverageTickets] = useState (null)
  const [schedulesByStatus, setSchedulesByStatus] = useState (null)
  const [newCustnersByMonth, setNewCustnersByMonth] = useState (null)
  const [soldItemsByMonth, setSoldItemsByMonth] = useState (null)
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [periodFilter, typeFilter])

  const getData = async () => {
    try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = now.toISODate ()
      const filters = `start_date=${startDate}&end_date=${endDate}&type=${typeFilter.value}`

      const { data: avgServiceTickets } = await cutileiApi.get (
        `/businesses/${businessId}/avg_service_ticket?${filters}`, requestConfig
      )

      const { data: avgCustomerTickets } = await cutileiApi.get (
        `/businesses/${businessId}/avg_customer_ticket?${filters}`, requestConfig
      )

      const { data: schedulesByStatus } = await cutileiApi.get (
        `/businesses/${businessId}/schedules_by_status?${filters}`, requestConfig
      )

      const { data: newCustnersByMonth } = await cutileiApi.get (
        `/businesses/${businessId}/new_customers?${filters}`, requestConfig
      )

      const { data: soldItemsByMonth } = await cutileiApi.get (
        `/businesses/${businessId}/sold_items?${filters}`, requestConfig
      )

      const statusValues = Object.values (schedulesByStatus)

      setAverageTickets ([
        ['Tickets médios', 'Por serviço', 'Por cliente'],
        ...Object.keys (avgServiceTickets).map (month => ([
          month,
          avgServiceTickets[month],
          avgCustomerTickets[month]
        ]))
      ])

      setSchedulesByStatus ([
        ['Status', 'Qauntidade'],
        ['Realizados', Object.values (statusValues)[statusValues.length-1].completedCount],
        ['Cliente faltou', Object.values (statusValues)[statusValues.length-1].missedCount],
        ['Cancelados', Object.values (statusValues)[statusValues.length-1].canceledCount]
      ])

      setNewCustnersByMonth ([
        ['Novos clientes', 'Clientes'],
        ...Object.keys (newCustnersByMonth).map (month => ([
          month,
          newCustnersByMonth[month]
        ]))
      ])

      setSoldItemsByMonth ([
        ['Item', 'Qauntidade'],
        ['Serviços', soldItemsByMonth[now.toFormat ('MMM yyyy')].services.total],
        ['Pacotes', soldItemsByMonth[now.toFormat ('MMM yyyy')].bundles.total],
        ['Produtos', soldItemsByMonth[now.toFormat ('MMM yyyy')].products.total]
      ])
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  return (
    <>
      <FilterContainer style={{marginTop: 0, marginBottom: 20}}>
        <Select
          name='periodFilter'
          placeholder='Período...'
          value={periodFilter}
          options={periodFilterOptions}
          onChange={(_, option) => setPeriodFilter (option)}
          containerStyles={selectStyle}
        />
        <Select
          name='scheduleType'
          placeholder='Tipo de agendamento...'
          value={typeFilter}
          options={typeFilterOptions}
          onChange={(_, option) => setTypeFilter (option)}
          containerStyles={selectStyle}
        />
      </FilterContainer>
      <Title>
        Mês / ano: {now.setLocale ('pt-BR').toFormat ('MMMM / yyyy')}
      </Title>
      <DashboardContainer>
        {loading ? (
          <LoadingContainer>
            <Loading/>
            <InfoText>Gerando gráficos...</InfoText>
          </LoadingContainer>
        ) : (
          <>
            <SchedulesByStatus data={schedulesByStatus} typeFilter={typeFilter}/>
            <SoldItems data={soldItemsByMonth}/>
            <AverageTickets data={averageTickets}/>
            <NewCustomers data={newCustnersByMonth}/>
          </>
        )}
      </DashboardContainer>
    </>
  )
}

export default Sales
