import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import AuthService from '../../../services/auth'
import cutileiApi from '../../../services/cutileiApi'
import AdminNavbar from '../../../components/AdminNavbar'
import MemberList from '../../../components/Lists/MemberList'
import ClearableInput from '../../../components/Inputs/ClearableInput'
import Select from '../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  TabRow,
  TabButton,
  FilterContainer,
  Row
} from './styles'

function Members () {
  const [loading, setLoading] = useState (true)
  const [status, setStatus] = useState (
    localStorage.getItem ('member_status') || 'active'
  )
  const [query, setQuery] = useState ('')
  const [cancelReason, setCancelReason] = useState (null)
  const [page, setPage] = useState (1)
  const [lastPage, setLastPage] = useState (0)
  const [total, setTotal] = useState (0)
  const [members, setMembers] = useState ([])
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const STATUS_LIST = [
    {label: 'Ativos', value: 'active', color: '#44CF6C'},
    {label: 'Inadimplentes', value: 'overdue', color: '#FFC100'},
    {label: 'Cancelados', value: 'canceled', color: '#FF3939'},
    {label: 'Leads', value: 'leads', color: '#35A7FF'}
  ]

  const CANCEL_OPTIONS = [
    {label: 'Motivo financeiro', value: 'Motivo financeiro'},
    {label: 'Disponibilidade de dias e horários', value: 'Disponibilidade de dias e horários'},
    {label: 'Atendimento dos salões', value: 'Atendimento dos salões'},
    {label: 'Ambiente dos salões', value: 'Ambiente dos salões'},
    {label: 'Atendimento dos profissionais', value: 'Atendimento dos profissionais'},
    {label: 'Serviço realizado', value: 'Serviço realizado'},
    {label: 'Outro', value: 'Outro'}
  ]

  useEffect (() => {
    getMembers (status, query, 1)
  }, [status, query, cancelReason])

  const getMembers = async (selectedStatus = status, nameQuery = query, pageNumber = page) => {
    if (lastPage && pageNumber > lastPage) return

    try {
      if (pageNumber === 1) setLoading (true)
      if (selectedStatus === 'leads') {
        const { data: leadsReponse } = await cutileiApi.get (
          `/leads?page=${pageNumber}&query=${nameQuery}`, requestConfig
        )

        const { data, meta } = leadsReponse
        const leadsData = pageNumber > 1 ? [...members, ...data] : data
        
        setMembers (leadsData)
        setPage (pageNumber + 1)
        setLastPage (meta.last_page)
        setTotal (meta.total)
      } else {
        const filters = `page=${pageNumber}&query=${nameQuery}&status=${selectedStatus}` + (
          selectedStatus === 'canceled' && cancelReason ? `&cancel_reason=${cancelReason.value}` : ''
        )

        const { data: membersResponse } = await cutileiApi.get (`/members?${filters}`, requestConfig)
        const { data, meta } = membersResponse
        const membersData = pageNumber > 1 ? [...members, ...data] : data

        setMembers (membersData)
        setPage (pageNumber + 1)
        setLastPage (meta.last_page)
        setTotal (meta.total)
      }
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  return (
    <Container>
      <AdminNavbar/>
      <TabRow>
        {STATUS_LIST.map (s =>
          <TabButton
            key={s.value}
            selected={status === s.value}
            color={s.color}
            onClick={() => {
              setStatus (s.value)
              localStorage.setItem ('member_status', s.value)
            }}
          >
            {s.label}
          </TabButton>
        )}
      </TabRow>
      <FilterContainer>
        <Row>
          <ClearableInput
            placeholder='Pesquisar por nome'
            value={query}
            onChange={e => setQuery (e.target.value)}
            onClear={() => setQuery ('')}
            containerStyle={{width: 280}}
            inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
          />
          {status === 'canceled' && (
            <Select
              name='cancel_reason'
              placeholder='Motivo de cancelamento...'
              value={cancelReason}
              options={CANCEL_OPTIONS}
              onChange={(_, option) => setCancelReason (option)}
              containerStyles={{boxShadow: '0px 1px 10px -6px', borderRadius: 15, width: 260}}
            />
          )}
        </Row>
      </FilterContainer>
      {loading ? <Loading style={{marginTop: 30}}/> :
        <MemberList
          members={members}
          totalMembers={total}
          hasMore={!(lastPage && page > lastPage)}
          onScroll={() => getMembers ()}
        />
      }
    </Container>
  )
}

export default withRouter (Members)
