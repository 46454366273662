import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 540px;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 10px;
  border-radius: 15px;

  @media (max-width: 640px) {
    width: 100%;
  }
`

export const Image = styled.img`
  width: 100px;
  height: 100px;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 20px;
  margin-bottom: 15px;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
`

export const Row = styled(Container)`
  width: 100%;
  padding: 0px;
  flex-direction: row;
  align-items: flex-start;
  gap: 7px;
`

export const InfoText = styled.p`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin-bottom: 0px;
`

export const MoneyText = styled(SubTitle)`
  color: #44CF6C;
`

export const LinkText = styled.a`
  color: #35A7FF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  &:hover {
    color: #2875E2;
  }
`
