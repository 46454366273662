import React from 'react'
import { FaStar } from 'react-icons/fa'

import {
  Container,
  RatingContainer,
  Info,
  Row,
  Image,
  SubTitle,
  InfoText
} from './styles'

function Rating ({ data: rating }) {
  return (
    <Container>
      <Row>
        <Image src={rating.member.profile_picture}/>
        <Info>
          <Row>
            <RatingContainer>
              <FaStar size={16} color='#FFC100'/>
              <SubTitle>
                {rating.value}
              </SubTitle>
            </RatingContainer>
            <SubTitle>
              {rating.member.name}
            </SubTitle>
          </Row>
          <InfoText>
            {rating.comment}
          </InfoText>
        </Info>
      </Row>
    </Container>
  )
}

export default Rating
