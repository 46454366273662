import styled from 'styled-components'
import { NumericFormat } from 'react-number-format'

export const modalStyle = {
  overlay: {
    zIndex: 5
  },
  content: {
    padding: 0,
    height: 'auto',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    zIndex: 5,
    marginRight: '-50%',
    maxHeight: 'calc(100vh - 150px)',
    transform: 'translate(-50%, -50%)',
    borderRadius: 15,
    boxShadow: '0px 2px 15px -8px #252525'
  }
}

export const selectStyle = {
  boxShadow: '0px 1px 10px -7px', borderRadius: 15
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  margin-left: 60px;
`

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 10px;
`

export const InfoContainer = styled.div`
  width: 580px;
  display: flex;
  box-shadow: 0px 2px 10px -6px #252525;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
`

export const BigInfoContainer = styled(InfoContainer)`
  width: 680px;
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 840px;
  background-color: #F7F7F7;
  padding: 10px;
  border-radius: 15px;
`

export const FilterContainer = styled.div`
  display: flex;
  min-width: 500px;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 15px;
  gap: 10px;
`

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 20px 0px 10px;
`

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
`

export const Column = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-left: 0px;
`

export const TabRow = styled(Row)`
  margin-bottom: 10px;
  gap: 10px;
`

export const LinkText = styled.button`
  color: #35A7FF;
  background-color: transparent;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
  border: 0px;
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
`

export const Title = styled(InfoText)`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`

export const SubTitle = styled(Title)`
  font-size: 14px;
  margin: 0px;
`

export const MoneyText = styled(NumericFormat).attrs (props => {
  return {
    value: props.value.toFixed (2).replace ('.', ','),
    thousandSeparator: '.',
    decimalSeparator: ',',
    displayType: 'text',
    prefix: 'R$ '
  }
})`
  color: ${({value}) => parseFloat (value.replace (',', '.')) >= 0 ? '#44CF6C' : '#FF3939'};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 0px;
`

export const Button = styled.button`
  color: #FFFFFF;
  background-color: #606060;
  font-size: 16px;
  height: 40px;
  margin: 0px 0px 8px;
  text-decoration: none;
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;

  &:hover {
    background-color: #404040;
  }
`

export const TabButton = styled.button`
  color: ${({ selected }) => selected ? '#FFFFFF' : "#000000"};
  background-color: ${({ selected, color }) => selected ? color : '#FFFFFF'};
  font-size: 14px;
  font-weight: bold;
  width: 170px;
  height: 38px;
  margin: 0px 0px 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -6px #252525;
  text-decoration: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => props.color};
  }
`

export const CloseButton = styled.button`
  position: fixed;
  top: 5px;
  right: 10px;
  width: 30px;
  height: 38px;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`
