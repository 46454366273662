import styled from 'styled-components'
import Cleave from 'cleave.js/react'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`

export const Form = styled.form`
  background-color: #F7F7F7;
  box-shadow: 0px 2px 10px -6px #252525;
  width: 400px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center !important;
  padding: 5px 10px;
`

export const StepRow = styled.div`
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
`

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
`

export const StepLabel = styled.p`
  color: #999999;
  font-size: 12px;
  margin: 0px;
`

export const StepCircle = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ step, currentStep }) => (
    currentStep.number > step.number
      ? '#44CF6C'
      : currentStep.number === step.number ? '#FFC100' : '#252525'
  )};

  p {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
    margin: 0px;
  }
`

export const FormField = styled(Container)`
  width: 100%;
  justify-content: flex-end;
  margin: 0px 0px 5px;
`

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
`

export const InfoText = styled.p`
  font-size: 14px;
  margin-bottom: 5px;
  text-align: center;
`

export const Input = styled.input`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const MaskedInput = styled(Cleave)`
  width: 100%;
  height: 38px;
  margin-top: 10px;
  border: 0px;
  box-shadow: 0px 2px 10px -8px #252525;
  border-radius: 15px;
  text-align: center;

  &::placeholder {
    color: #BBBBBB
  }

  &:disabled {
    background-color: #FFFFFF;
  }
  
  &:focus {
    border: 1px solid #CCCCCC !important;
    outline: none;
  }
`

export const Button = styled.button`
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;
  margin: 10px 0px 5px;
  width: 50%;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`

export const WarningText = styled.p`
  margin: 2px 0px 0px;
  color: #FF3939;
  text-align: center;
`

export const ErrorContainer = styled.div`
  padding: 9px;
  margin-top: 15px;
  width: 100%;
  border-radius: 15px;
  text-align: center;
  background-color: #FF3939;

  p {
    color: #FFFFFF;
    margin: 0px;
  }
`
