import React, { useState } from 'react'
import AuthService from '../../services/auth'
import cutileiApi from '../../services/cutileiApi'
import Logo from '../../icons/logo-name.png'
import PasswordInput from '../../components/Inputs/PasswordInput'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { ReactComponent as ButtonLoading } from '../../icons/loading.svg'

import {
  Container,
  Form,
  Image,
  Title,
  LinkText,
  Label,
  Input,
  ButtonContainer,
  Button,
  LinkButton,
  WarningText,
  ErrorContainer
} from './styles'

function SignIn ({ history }) {
  const [errorMessage, setErrorMessage] = useState (null)

  const signInValidation = Yup.object ({
    'email': Yup
      .string ()
      .required ('É necessário fornecer um e-mail')
      .email ('Insira um email válido'),
  
    'password': Yup
      .string ()
      .required ('É necessário fornecer uma senha')
      .min (8, 'A senha deve conter no minimo 8 caracteres'),
  })

  return (
    <Container>
      <Formik
        validationSchema={signInValidation}
        initialValues={{
          'email': '',
          'password': '',
        }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            setSubmitting (true)
      
            const { data } = await cutileiApi.post ('/auth/businesses', values)
            
            setErrorMessage (null)
            AuthService.signIn (data)
            history.replace ('/business')
          } catch (error) {
            setErrorMessage (error.response.data.message)
          } finally {
            setSubmitting (false)
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        }) => (
          <Form onSubmit={handleSubmit}>
            <Image src={Logo} alt='Cutilei'/>
            <Title>Entrar</Title>
            <Input
              type='email'
              placeholder='E-mail'
              value={values.email}
              onChange={handleChange ('email')}
              onBlur={handleBlur ('email')}
            />
            {touched.email && errors.email && (
              <WarningText>
                {errors.email}
              </WarningText>
            )}
            <PasswordInput
              placeholder='Senha'
              value={values.password}
              onChange={handleChange ('password')}
              onBlur={handleBlur ('password')}
              containerStyle={{marginTop: 10}}
            />
            {touched.password && errors.password && (
              <WarningText>
                {errors.password}
              </WarningText>
            )}
            <LinkText to='/forgot-password'>
              Esqueceu sua senha?
            </LinkText>
            {errorMessage && (
              <ErrorContainer>
                <p>{errorMessage}</p>
              </ErrorContainer>
            )}
            <ButtonContainer>
              <Button type='submit' disabled={isSubmitting}>
                {isSubmitting ? <ButtonLoading/> : 'Entrar'}
              </Button>
              <Label>Ainda não cadastrou seu salão?</Label>
              <LinkButton to='/signup'>
                Cadastrar-se
              </LinkButton>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default SignIn
