import React, { useState, useEffect } from 'react'
import AuthService from '../../services/auth'
import cutileiApi from '../../services/cutileiApi'
import CustomerService from '../../services/Customer'
import AddCustomerModal from '../Modals/AddCustomerModal'
import Navbar from '../../components/Navbar'
import ClearableInput from '../../components/Inputs/ClearableInput'
import CustomerList from '../../components/Lists/CustomerList'
import { ReactComponent as Loading } from '../../icons/loading2.svg'

import {
  Container,
  Row,
  Button
} from './styles'

function Customers () {
  const [loading, setLoading] = useState (true)
  const [query, setQuery] = useState ('')
  const [page, setPage] = useState (1)
  const [lastPage, setLastPage] = useState (0)
  const [total, setTotal] = useState (0)
  const [customers, setCustomers] = useState ([])
  const [showAddCustomerModal, setShowAddCustomerModal] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getCustomers (1)
  }, [query])

  const getCustomers = async (pageNumber = page, _query = query)  => {
    if (lastPage && pageNumber > lastPage) return

    try {
      const { data: customersResponse } = await cutileiApi.get (
        `/businesses/${businessId}/customers?page=${pageNumber}&query=${_query}`,
        requestConfig
      )
      
      const { data, meta } = customersResponse
      const customersData = pageNumber > 1 ? [...customers, ...data] : data
      const sortedCustomers = CustomerService.sortCustomersByQuery (customersData, _query)

      setCustomers (sortedCustomers)
      setPage (pageNumber + 1)
      setLastPage (meta.last_page)
      setTotal (meta.total)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const toggleAddCustomerModal = () => setShowAddCustomerModal (!showAddCustomerModal)

  return (
    <Container>
      <Navbar/>
      <Row>
        <ClearableInput
          placeholder='Pesquisar por nome, e-mail ou celular'
          value={query}
          onChange={e => setQuery (e.target.value)}
          onClear={() => {
            setQuery ('')
            getCustomers (1, '')
          }}
          containerStyle={{width: 300}}
          inputStyle={{boxShadow: '0px 1px 10px -6px', paddingInline: 12}}
        />
        <Button style={{margin: 0}} onClick={toggleAddCustomerModal}>
          Cadastrar cliente
        </Button>
      </Row>
      {loading ? <Loading style={{marginTop: 30}}/> :
        <CustomerList
          customers={customers}
          totalCustomers={total}
          hasMore={!(lastPage && page > lastPage)}
          onScroll={() => getCustomers ()}
          onEdit={customer => setCustomers (customers.map (c => c.id === customer.id ? {...customer} : c))}
          onDelete={customer => setCustomers (customers.filter (c => c.id !== customer.id))}
        />
      }
      <AddCustomerModal
        visible={showAddCustomerModal}
        query={query}
        onConfirm={customer => setCustomers ([...customers.filter (c => c.id !== customer.id), customer])}
        onClose={toggleAddCustomerModal}
      />
    </Container>
  )
}

export default Customers
