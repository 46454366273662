import React from 'react'
import Schedule from './Schedule'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'

import {
  Container,
  SubTitle,
  InfoText,
  LabelRow
} from './styles'

function ScheduleList ({
  schedules,
  totalSchedules,
  detailed = false,
  hasMore,
  endMessage,
  onScroll: handleScroll,
  onScheduleSelected: handleScheduleSelected = () => null,
}) {
  return (
    <Container>
      {detailed && totalSchedules !== undefined && totalSchedules > 0 &&
        <SubTitle>
          {totalSchedules + ' ' + (totalSchedules === 1 ? 'Resultado' : 'Resultados')}
        </SubTitle>
      }
      {schedules.length > 0 ? (
        <>
          <LabelRow detailed={detailed}>
            {detailed && <SubTitle>Cliente</SubTitle>}
            <SubTitle>Serviço</SubTitle>
            <SubTitle>Profissional</SubTitle>
            <SubTitle>Data</SubTitle>
            <SubTitle>Horário</SubTitle>
            <SubTitle>Comissão (%)</SubTitle>
            <SubTitle style={{justifySelf: 'center'}}>Preço</SubTitle>
          </LabelRow>
          <InfiniteScroll
            dataLength={schedules.length}
            next={handleScroll}
            hasMore={hasMore}
            loader={<Loading/>}
            endMessage={endMessage}
            scrollableTarget='customer-modal'
            style={{ overflow: 'visible' }}
          >
            {schedules.map (schedule =>
              <Schedule
                key={schedule.id}
                detailed={detailed}
                data={schedule}
                onClick={handleScheduleSelected}
              />
            )}
          </InfiniteScroll>
        </>
      ) : (
        <InfoText>Nenhum agendamento encontrado</InfoText>
      )}
    </Container>
  )
}

export default ScheduleList
