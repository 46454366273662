import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import Phone from '../../../services/Phone'
import AdminNavbar from '../../../components/AdminNavbar'
import AlertDialog from '../../../components/AlertDialog'
import Address from '../../../services/Address'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import { FaStar } from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  Container,
  Info,
  Image,
  Row,
  RatingContainer,
  Title,
  SubTitle,
  InfoText,
  LinkText,
  Block,
  Button,
  DangerButton
} from './styles'

function Business ({ history }) {
  const { business } = history.location.state
  const [loading, setLoading] = useState (true)
  const [submitting, setSubmitting] = useState (false)
  const [workingDays, setWorkingDays] = useState ([])
  const [professionals, setProfessionals] = useState ([])
  const [isBusinessActive, setBusinessActive] = useState (business.active)
  const [showActivationDialog, setShowActivationDialog] = useState (false)
  const address = Address.formatAddress (business.addresses[0])
  const addressUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    getData ()
  }, [])

  const getData = async () => {
    try {
      const { data: workingDays } = await cutileiApi.get (
        `/businesses/${business.id}/working_days`, requestConfig
      )

      const { data: professionals } = await cutileiApi.get (
        `/businesses/${business.id}/professionals`, requestConfig
      )

      setWorkingDays (workingDays)
      setProfessionals (professionals)
    } catch (error) {
      console.log (error)
    } finally {
      setLoading (false)
    }
  }

  const handleAccessBusiness = () => {
    AuthService.accessBusiness ({
      id: business.id,
      name: business.name,
      email: business.email,
      logo: business.logo,
      access_level: 'business'
    })

    history.replace ('/business')
  }

  const toggleBusinessActivation = async () => {
    try {
      setSubmitting (true)

      await cutileiApi.put (`/businesses/${business.id}/activation`, {
        active: !isBusinessActive
      }, requestConfig)

      setBusinessActive (!isBusinessActive)
    } catch (error) {
      console.log (error)
    } finally {
      setSubmitting (false)
    }
  }

  const toggleActivationDialog = () => setShowActivationDialog (!showActivationDialog)

  return loading ? null : (
    <Container>
      <AdminNavbar/>
      <Info>
        <Title>{business.name}</Title>
        <Row style={{position: 'relative', width: 'auto'}}>
          <Image src={business.logo}/>
          <RatingContainer>
            <FaStar size={16} color='#FFC100'/>
            <SubTitle>
              {business?.average_rating}
            </SubTitle>
          </RatingContainer>
        </Row>
        <Block>
          <Row>
            <SubTitle>Razão social: &nbsp;</SubTitle>
            <InfoText>{business.corporate_name}</InfoText>
          </Row>
          <Row>
            <SubTitle>Responsável: &nbsp;</SubTitle>
            <InfoText>{business.owner}</InfoText>
          </Row>
          <Row>
            <SubTitle>CPNJ: &nbsp;</SubTitle>
            <InfoText>{business.cnpj}</InfoText>
          </Row>
          <Row>
            <SubTitle>Contato: &nbsp;</SubTitle>
            <InfoText>{Phone.formatPhone (business.cellphone)}</InfoText>
          </Row>
        </Block>
        <Block>
          <SubTitle>Endereço:</SubTitle>
          <LinkText href={addressUrl} target='_blank' rel='noopener noreferrer'>
            <SubTitle>{address}</SubTitle>
          </LinkText>
        </Block>
        <Block>
          <SubTitle>Dias disponíveis:</SubTitle>
          {workingDays.length > 0 ? workingDays.map (day => 
            <Row key={day.id}>
              <InfoText>
                {day.name}: &nbsp;
              </InfoText>
              <SubTitle>
                {`${DateTime.fromISO (day.opening).toFormat ('H:mm')} - ${DateTime.fromISO (day.closing).toFormat ('H:mm')}`}
              </SubTitle>
            </Row>
          ) : (
            <InfoText>Este salão ainda não cadastrou os dias disponíveis</InfoText>
          )}
        </Block>
        <Block>
          <Row>
            <SubTitle>Cadastro: &nbsp;</SubTitle>
            <InfoText>
              {DateTime.fromISO (business.created_at).toFormat ('dd/MM/yyyy - H:mm:ss')}
            </InfoText>
          </Row>
          <Row>
            <SubTitle>Última alteração: &nbsp;</SubTitle>
            <InfoText>
              {DateTime.fromISO (business.updated_at).toFormat ('dd/MM/yyyy - H:mm:ss')}
            </InfoText>
          </Row>
        </Block>
        <Row>
          <Button onClick={handleAccessBusiness}>
            Acessar salão
          </Button>
          {isBusinessActive ? (
            <DangerButton onClick={toggleActivationDialog}>
              {submitting ? <ButtonLoading/> : 'Desativar salão'}
            </DangerButton>
          ) : (
            <Button onClick={toggleActivationDialog}>
              {submitting ? <ButtonLoading/> : 'Ativar salão'}
            </Button>
          )}
        </Row>
      </Info>
      <AlertDialog
        visible={showActivationDialog}
        title='Atenção!'
        message={`Deseja realmente ${isBusinessActive ? 'desativar' : 'ativar'} este salão?`}
        confirmText='Sim'
        cancelText='Não'
        onConfirm={toggleBusinessActivation}
        onClose={toggleActivationDialog}
        dangerous={isBusinessActive}
      />
    </Container>
  )
}

export default withRouter (Business)
