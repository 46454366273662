import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import TimeWindow from '../../../services/timeWindow'
import ScheduleReservation from '../../../services/ScheduleReservation'
import Select from '../../../components/Inputs/Select'
import CheckBox from '../../../components/CheckBox'
import AlertDialog from '../../../components/AlertDialog'
import OptionDialog from '../../../components/OptionDialog'
import { Formik } from 'formik'
import editReservationValidator from '../../../validators/editReservationValidator'
import { ReactComponent as Loading } from '../../../icons/loading2.svg'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  Title,
  InfoText,
  Label,
  Button,
  CloseButton,
  DangerButton,
  Form,
  FormField,
  Input,
  Row,
  WeekdayContainer,
  WarningText,
  ButtonText,
  ErrorContainer
} from './styles'

function EditReservationModal ({
  visible,
  date,
  data: reservation,
  onConfirm: handleConfirm,
  onClose: handleClose,
  onDelete: handleDelete
}) {
  const [loading, setLoading] = useState (true)
  const [deleting, setDeleting] = useState (false)
  const [errorMessage, setErrorMessage] = useState (null)
  const [weekdays, setWeekdays] = useState ([])
  const [longestDay, setLongestDay] = useState (null)
  const [professionals, setProfessionals] = useState ([])
  const [period, setPeriod] = useState ({})
  const [startTimeWindows, setStartTimeWindows] = useState ([])
  const [endTimeWindows, setEndTimeWindows] = useState ([])
  const [selectAllDays, setSelectAllDays] = useState (reservation.weekdays.length === 7)
  const [showEditReservationDialog, setShowEditReservationDialog] = useState (false)
  const [showDeleteReservationDialog, setShowDeleteReservationDialog] = useState (false)
  const selectedISODate = date.toISODate ()
  const previousDayISODate = date.minus ({days: 1}).toISODate ()
  const nextDayISODate = date.plus ({days: 1}).toISODate ()
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const reservationValues = {
    professionalId: reservation.professional_id,
    description: reservation.description,
    startDate: reservation.start_date,
    endDate: reservation.end_date,
    startTime: reservation.start_time,
    endTime: reservation.end_time,
    weekdays: reservation.weekdays
  }

  useEffect (() => {
    if (visible) getData ()
    setSelectAllDays (reservation.weekdays.length === 7)
  }, [visible])

  useEffect (() => {
    setPeriod (getPeriod (reservation))
  }, [reservation])

  const getData = async () => {
    try {
      const { data: weekdays } = await cutileiApi.get ('/weekdays')
      const { data: workingDays } = await cutileiApi.get (`/businesses/${businessId}/working_days`)
      const { data: professionals } = await cutileiApi.get (
        `/businesses/${businessId}/professionals?status=active&access_level=service`,
        requestConfig
      )

      const longestDay = workingDays.sort (day => (
        DateTime.fromISO (day.opening) < DateTime.fromISO (day.closing)
      ))[0]

      setStartTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, longestDay.closing))
      setEndTimeWindows (TimeWindow.getTimeWindows (longestDay.opening, longestDay.closing))
      
      setWeekdays (weekdays)
      setLongestDay (longestDay)
      setProfessionals (professionals.map (professional => ({
        label: professional?.nickname ? professional?.nickname : professional?.name,
        value: professional?.id
      })))
    } catch (error) {
      console.log (error.response)
    } finally {
      setLoading (false)
    }
  }

  const getPeriod = (reservation) => {
    const period = DateTime.fromISO (reservation.end_date)
      .diff (DateTime.fromISO (reservation.start_date), ['days', 'months']).toObject ()

    const getLabel = () => {
      if (!period.months) return period.days === 0 || period.days === 7 || period.days === 15
        ? period.days > 0 ? `${period.days} dias` : '1 dia'
        : 'Personalizado'
      else if (period.months >= 5000) return 'Indeterminado'
      else return `${period.months} mês`
    }

    const getValue = () => {
      if (period.months === 1) return 30
      else if (period.months >= 5000) return -1
      else return period.days === 0 || period.days === 7 || period.days === 15 ? period.days : -2
    }

    return { label: getLabel (), value: getValue () }
  }

  const handlePeriodChange = (name, option, values, setFieldValue) => {
    let interval = null
    const { weekdays } = values
    const startDate = DateTime.fromISO (values.startDate)

    switch (option.value) {
      case -2: interval = null; break
      case -1: interval = {years: 500}; break
      case 30: interval = {months: 1}; break
      default: interval = {days: option.value}
    }

    if (interval) {
      const endDate = startDate.plus (interval).toISODate ()
      setFieldValue ('endDate', endDate)
    }

    if (option.value === 0)
      setFieldValue ('weekdays', weekdays.map (day => ({
        ...day, selected: day.iso_number === startDate.weekday
      })))
    else
      handleSelectAllDays (option.value > -1 && option.value <= 15, weekdays, setFieldValue)
    setFieldValue (name, option)
  }

  const handleStartDateSelected = (startDate, values, setFieldValue) => {
    if (values.period?.value === 0)
      setFieldValue ('weekdays', weekdays.map (day => ({
        ...day, selected: day.iso_number === DateTime.fromISO (startDate).weekday
      })))
    setFieldValue ('startDate', startDate)
  }

  const handleEndDateSelected = (endDate, values, setFieldValue) => {
    const { startDate } = values
    const dateInterval = { startDate, endDate }

    handleSelectAllDays (getPeriodInDays (dateInterval) <= 15, weekdays, setFieldValue)
    setFieldValue ('endDate', endDate)
  }

  const handleTimeSelected = (name, option, setFieldValue) => {
    if (name === 'startTime') {
      setEndTimeWindows (
        TimeWindow.getTimeWindows (option.value, longestDay.closing).filter (tw => 
          tw.value !== option.value
        )
      )
    } else if (name === 'endTime') {
      setStartTimeWindows (
        TimeWindow.getTimeWindows (longestDay.opening, option.value).filter (tw => 
          tw.value !== option.value
        )
      )
    }
    setFieldValue (name, option)
  }

  const handleSelectAllDays = (value, weekdays, setFieldValue) => {
    setFieldValue ('weekdays', weekdays.map (day => ({...day, selected: value})))
    setSelectAllDays (value)
  }

  const deleteReservation = async () => {
    try {
      setDeleting (true)
      await cutileiApi.delete (`/schedule_reservations/${reservation.id}`, requestConfig)
      handleDelete (reservation)
      handleClose ()
    } catch (error) {
      setErrorMessage (error.response?.data.message)
      console.log (error)
    } finally {
      setDeleting (false)
    }
  }

  const editDay = async (values, setSubmitting) => {
    const { professional, description, startTime, endTime, weekdays } = values

    try {
      await cutileiApi.post (`/businesses/${businessId}/schedule_reservations`, {
        ...reservationValues,
        ...(selectedISODate === reservation.start_date ? {
          startDate: nextDayISODate
        } : {
          endDate: previousDayISODate
        })
      }, requestConfig)

      if (![reservation.start_date, reservation.end_date].includes (selectedISODate))
        cutileiApi.post (`/businesses/${businessId}/schedule_reservations`, {
          ...reservationValues,
          startDate: nextDayISODate
        }, requestConfig)

      const { data: reservationData } = await cutileiApi.put (`/schedule_reservations/${reservation.id}`, {
        professionalId: professional.value,
        description,
        startDate: selectedISODate,
        endDate: selectedISODate,
        startTime: startTime.value,
        endTime: endTime.value,
        weekdays: weekdays.filter (day => day.selected)
      }, requestConfig)

      setSubmitting (false)
      handleConfirm (reservationData)
      handleClose ()
    } catch (error) {
      setSubmitting (false)
      setErrorMessage (error.response?.data.message)
      console.log (error)
    }
  }

  const deleteDay = async () => {
    try {
      setDeleting (true)

      await cutileiApi.put (`/schedule_reservations/${reservation.id}`, {
        ...reservationValues,
        ...(selectedISODate === reservation.start_date ? {
          startDate: nextDayISODate
        } : {
          endDate: previousDayISODate
        })
      }, requestConfig)

      if (![reservation.start_date, reservation.end_date].includes (selectedISODate))
        cutileiApi.post (`/businesses/${businessId}/schedule_reservations`, {
          ...reservationValues,
          startDate: nextDayISODate
        }, requestConfig)

      handleDelete (reservation)
      handleClose ()
    } catch (error) {
      setErrorMessage (error.response?.data.message)
      console.log (error)
    } finally {
      setDeleting (false)
    }
  }

  const getPeriodInDays = values => {
    const startDate = DateTime.fromISO (values.startDate)
    const endDate = DateTime.fromISO (values.endDate)
    const periodInDays = endDate.diff (startDate, 'days').toObject ().days

    return periodInDays
  }

  const toggleEditReservationDialog = () => setShowEditReservationDialog (!showEditReservationDialog)
  const toggleDeleteReservationDialog = () => setShowDeleteReservationDialog (!showDeleteReservationDialog)

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      {loading ? <Loading/> : (
        <Formik
          validationSchema={editReservationValidator}
          initialValues={{
            'professional': {
              label: professionals.find (professional => (
                professional.value === reservation.professional_id
              )).label,
              value: reservation.professional_id
            },
            'description': reservation.description,
            'startDate': reservation.start_date,
            'endDate': reservation.end_date,
            'period': period,
            'startTime': {
              label: reservation.start_time,
              value: reservation.start_time
            },
            'endTime': {
              label: reservation.end_time,
              value: reservation.end_time
            },
            'weekdays': weekdays.map (day => {
              const reservationWeekDay = reservation.weekdays.find (weekDay => weekDay.id === day.id)

              return reservationWeekDay ? { 
                ...reservationWeekDay, selected: true
              } : {
                ...day, selected: false
              }
            })
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const {
              professional, description, startDate, endDate, period, startTime, endTime, weekdays
            } = values

            try {
              const { data: reservationData } = await cutileiApi.put (`/schedule_reservations/${reservation.id}`, {
                professionalId: professional.value,
                description,
                startDate,
                endDate: period.value === 0 ? startDate : endDate,
                startTime: startTime.value,
                endTime: endTime.value,
                weekdays: weekdays.filter (day => day.selected)
              }, requestConfig)

              setSubmitting (false)
              handleConfirm (reservationData)
              handleClose ()
            } catch (error) {
              setSubmitting (false)
              if (error.response?.status === 403)
                setErrorMessage (error.response.data.message)
              console.log (error)
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            handleChange,
            handleBlur,
            handleSubmit,
            setSubmitting
          }) => (
            <Form>
              <Title>Alterar reserva de agenda</Title>
              <CloseButton onClick={handleClose}>
                <FA.FaTimes color='#FF3939' size={18}/>
              </CloseButton>
              <Row>
                <FormField>
                  <Select
                    name='professional'
                    placeholder='Profissional...'
                    value={values.professional}
                    options={professionals}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.professional}
                    touched={touched.professional}
                  />
                  {touched.professional && errors.professional && (
                    <WarningText>
                      {errors.professional}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Input 
                    placeholder='Descrição da reserva'
                    value={values.description}
                    onChange={handleChange ('description')} 
                    onBlur={handleBlur ('description')}
                  />
                  {touched.description && errors.description && (
                    <WarningText>
                      {errors.description}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              <Row>
                <FormField>
                  <Input
                    type='date'
                    placeholder='Data'
                    value={values.startDate}
                    onChange={e => handleStartDateSelected (e.target.value, values, setFieldValue)}
                    onBlur={handleBlur ('startDate')}
                  />
                  {touched.startDate && errors.startDate && (
                    <WarningText>
                      {errors.startDate}
                    </WarningText>
                  )}
                </FormField>
                <FormField style={{marginLeft: 5, marginRight: values.period?.value === -2 ? 5 : 0}}>
                  <Select
                    name='period'
                    placeholder='Período...'
                    value={values.period}
                    options={ScheduleReservation.PERIODS}
                    onChange={(name, option) => handlePeriodChange (name, option, values, setFieldValue)}
                    onBlur={setFieldTouched}
                    error={errors.period}
                    touched={touched.period}
                  />
                  {touched.period && errors.period && (
                    <WarningText>
                      {errors.period}
                    </WarningText>
                  )}
                </FormField>
                {values.period?.value === -2 &&
                  <FormField>
                    <Input
                      type='date'
                      placeholder='Data final'
                      value={values.endDate}
                      onChange={e => handleEndDateSelected (e.target.value, values, setFieldValue)}
                      onBlur={handleBlur ('endDate')}
                    />
                    {touched.endDate && errors.endDate && (
                      <WarningText>
                        {errors.endDate}
                      </WarningText>
                    )}
                  </FormField>
                }
              </Row>
              <Row>
                <FormField>
                  <Select
                    name='startTime'
                    placeholder='Horário inicial...'
                    value={values.startTime}
                    options={startTimeWindows}
                    onChange={(name, option) => handleTimeSelected (name, option, setFieldValue)}
                    onBlur={setFieldTouched}
                    error={errors.startTime}
                    touched={touched.startTime}
                  />
                  {touched.startTime && errors.startTime && (
                    <WarningText>
                      {errors.startTime}
                    </WarningText>
                  )}
                </FormField>
                <FormField>
                  <Select
                    name='endTime'
                    placeholder='Horário final...'
                    value={values.endTime}
                    options={endTimeWindows}
                    onChange={(name, value) => handleTimeSelected (name, value, setFieldValue)}
                    onBlur={setFieldTouched}
                    error={errors.endTime}
                    touched={touched.endTime}
                  />
                  {touched.endTime && errors.endTime && (
                    <WarningText>
                      {errors.endTime}
                    </WarningText>
                  )}
                </FormField>
              </Row>
              {getPeriodInDays (values) > 15 && (
                <FormField style={{marginBottom: 10}}>
                  <Label style={{marginBottom: 5}}>
                    Selecione os dias da semana em que deseja repetir esta reserva
                  </Label>
                  <WeekdayContainer>
                    {weekdays.map ((day, index) => (
                      <Row key={day.id} style={{alignItems: 'center', margin: 0}}>
                        <CheckBox
                          id={`weekdays.${index}.selected`}
                          style={{marginRight: 6}}
                          value={values.weekdays[index].selected}
                          onValueChange={value => setFieldValue (`weekdays.${index}.selected`, value)}
                        />
                        <Label htmlFor={`weekdays.${index}.selected`}>
                          {day.name.substring (0, 3)}
                        </Label>
                      </Row>
                    ))}
                  </WeekdayContainer>
                  <Row style={{alignItems: 'center', marginBottom: 4}}>
                    <CheckBox
                      id='select_all'
                      style={{marginRight: 6}}
                      value={selectAllDays}
                      onValueChange={value => handleSelectAllDays (value, values.weekdays, setFieldValue)}
                    />
                    <Label htmlFor='select_all'>
                      Selecionar todos os dias
                    </Label>
                  </Row>
                  {touched.weekdays && errors.weekdays && (
                    <WarningText>
                      {errors.weekdays}
                    </WarningText>
                  )}
                </FormField>
              )}
              {errorMessage && (
                <ErrorContainer>
                  <ButtonText>{errorMessage}</ButtonText>
                </ErrorContainer>
              )}
              <Button
                type='button'
                onClick={period.value !== 0 ? toggleEditReservationDialog : handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <ButtonLoading/> : 'Salvar alterações'}
              </Button>
              <DangerButton type='button' onClick={toggleDeleteReservationDialog}>
                {deleting ? <ButtonLoading/> : 'Excluir reserva'}
              </DangerButton>
              {period.value !== 0 ? (
                <>
                  <OptionDialog
                    visible={showEditReservationDialog}
                    title='Atenção!'
                    message={
                      <InfoText style={{fontSize: 14, margin: 0}}>
                        Deseja alterar a reserva em todos os dias de seu período 
                        ou apenas o dia selecionado ({date.toFormat ('dd/MM/yyyy')})?
                      </InfoText>
                    }
                    options={[
                      {label: 'Alterar reserva', onClick: handleSubmit, dangerous: false},
                      {label: 'Alterar dia', onClick: () => editDay (values, setSubmitting), dangerous: false}
                    ]}
                    onClose={toggleEditReservationDialog}
                    containerStyle={{width: 420}}
                    buttonStyle={{width: 160}}
                  />
                  <OptionDialog
                    visible={showDeleteReservationDialog}
                    title='Atenção!'
                    message={
                      <InfoText style={{fontSize: 14, margin: 0}}>
                        Deseja excluir esta reserva em todos os dias de seu período 
                        ou apenas o dia selecionado ({date.toFormat ('dd/MM/yyyy')})?
                      </InfoText>
                    }
                    options={[
                      {label: 'Excluir reserva', onClick: deleteReservation, dangerous: true},
                      {label: 'Excluir dia', onClick: deleteDay, dangerous: false}
                    ]}
                    onClose={toggleDeleteReservationDialog}
                    containerStyle={{width: 420}}
                    buttonStyle={{width: 160}}
                  />
                </>
              ) : (
                <AlertDialog
                  visible={showDeleteReservationDialog}
                  title='Atenção!'
                  message='Deseja realmente excluir esta reserva de agenda?'
                  confirmText='Sim'
                  cancelText='Não'
                  onConfirm={deleteReservation}
                  onClose={toggleDeleteReservationDialog}
                  dangerous
                />
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  )
}

export default EditReservationModal
