import React from 'react'
import LineChart from '../../../../components/Charts/LineChart'
import { BigInfoContainer, SubTitle } from '../../styles'

function NewCustomers ({ data: newCustomers }) {
  return (
    <BigInfoContainer>
      <SubTitle>Novos clientes por mês</SubTitle>
      <LineChart
        data={newCustomers}
        options={{
          height: 350,
          colors: ['#44CF6C'],
          chartArea: {
            top: 30, left: 110, width: '64%', height: '75%'
          },
          vAxis: {
            title: 'Novos clientes',
            minValue: 0,
            titleTextStyle: {
              bold: true
            }
          }
        }}
      />
    </BigInfoContainer>
  )
}

export default NewCustomers
