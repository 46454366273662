import React, { useState } from 'react'
import EditCutileiReservations from './CutileiReservations'
import EditWorkingDays from './WorkingDays'

import {
  Container,
  TabRow,
  TabButton
} from '../styles'

function EditAgenda (props) {
  const [option, setOption] = useState ('working_days')

  const OPTION_LIST = [
    {label: 'Dias e horários', value: 'working_days', component: <EditWorkingDays {...props}/>},
    {label: 'Reservas Cutilei', value: 'cutilei_reservations', component: <EditCutileiReservations/>}
  ]

  return (
    <Container style={{paddingTop: 0}}>
      <TabRow style={{paddingTop: 10}}>
        {OPTION_LIST.map ((o, index) =>
          <TabButton
            key={index}
            selected={option === o.value}
            color={option === o.value ? '#FFC100' : '#FFFFFF'}
            onClick={() => setOption (o.value)}
          >
            {o.label}
          </TabButton>
        )}
      </TabRow>
      {OPTION_LIST.find (o => o.value === option).component}
    </Container>
  )
}

export default EditAgenda
