import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import AuthService from '../../../../services/auth'
import cutileiApi from '../../../../services/cutileiApi'
import Dashboard from '../../../../services/Dashboard'
import Comission from '../../../../services/Comission'
import PieChart from '../../../../components/Charts/PieChart'
import ColumnChart from '../../../../components/Charts/ColumnChart'
import Select from '../../../../components/Inputs/Select'
import { ReactComponent as Loading } from '../../../../icons/loading2.svg'
import * as FA from 'react-icons/fa'
import { DateTime } from 'luxon'

import {
  modalStyle,
  selectStyle,
  InfoContainer,
  ModalContainer,
  FilterContainer,
  LoadingContainer,
  CloseButton,
  SubTitle,
  InfoText,
  LinkText
} from '../../styles'

function EarningsByProfessional ({
  data: earnings,
  selectedMonth = DateTime.now ().month,
  selectedYear = DateTime.now ().year
}) {
  const now = DateTime.now ().setLocale ('pt-BR')
  const formattedNow = now.toFormat ('MMM yyyy')
  const token = AuthService.getToken ()
  const businessId = AuthService.getBusinessId ()
  const periodFilterOptions = Dashboard.PERIOD_FILTERS
  const typeFilterOptions = Dashboard.TYPE_FILTERS
  const [loading, setLoading] = useState (true)
  const [isMounted, setMounted] = useState (false)
  const [periodFilter, setPeriodFilter] = useState (null)
  const [typeFilter, setTypeFilter] = useState (null)
  const [showDetails, setShowDetails] = useState (false)
  const [earningsByMonth, setEarningsByMonth] = useState ([])

  const earningsByProfessional = [
    ['Profissional', 'Valor (R$)'],
    ...Object.keys (Object.values (earnings)[0]).map (data => ([
      data, earnings[formattedNow][data].totalValue
    ]))
  ]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  useEffect (() => {
    if (isMounted) {
      setPeriodFilter (periodFilterOptions[0])
      setTypeFilter (typeFilterOptions[0])
    }
  }, [isMounted])

  useEffect (() => {
    getData ()
  }, [periodFilter, typeFilter])

  const getData = async () => {
    if (showDetails) try {
      setLoading (true)

      const startDate = now.minus ({months: periodFilter.value}).startOf ('month').toISODate ()
      const endDate = now.toISODate ()
      const filters = `start_date=${startDate}&end_date=${endDate}&type=${typeFilter.value}`

      const { data: earningsByProfessional } = await cutileiApi.get (
        `/businesses/${businessId}/earnings_by_professional?${filters}`, requestConfig
      )

      const professionals = Dashboard.getKeysFromPeriod (earningsByProfessional)

      setEarningsByMonth ([
        ['Profissionais', ...professionals],
        ...Object.values (earningsByProfessional).map ((month, index) => ([
          Object.keys (earningsByProfessional)[index],
          ...professionals.map (name => month[name]?.totalValue || 0)
        ]))
      ])
    } catch (error) {
      console.log (error.response?.data)
    } finally {
      setLoading (false)
    }
  }

  const toggleDetails = () => {
    if (!isMounted) setMounted (true)
    setShowDetails (!showDetails)
  }

  return (
    <InfoContainer>
      <SubTitle>Faturamento por profissional</SubTitle>
      <PieChart data={earningsByProfessional}/>
      <LinkText onClick={toggleDetails}>
        Ver histórico
      </LinkText>
      <Modal
        isOpen={showDetails}
        onRequestClose={toggleDetails}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={modalStyle}
      >
        <ModalContainer>
          <SubTitle>Faturamento por profissional</SubTitle>
          <CloseButton onClick={toggleDetails}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
          <FilterContainer style={{width: 520}}>
            <Select
              name='timeFilter'
              placeholder='Período...'
              value={periodFilter}
              options={periodFilterOptions}
              onChange={(_, option) => setPeriodFilter (option)}
              containerStyles={selectStyle}
            />
            <Select
              name='scheduleType'
              placeholder='Tipo de agendamento...'
              value={typeFilter}
              options={typeFilterOptions}
              onChange={(_, option) => setTypeFilter (option)}
              containerStyles={selectStyle}
            />
          </FilterContainer>
          {loading ? (
            <LoadingContainer>
              <Loading/>
              <InfoText>Gerando gráfico...</InfoText>
            </LoadingContainer>
          ) : (
            <ColumnChart
              data={earningsByMonth}
              options={{
                height: 450,
                chartArea: {
                  top: 40, left: 110, width: '70%', height: '75%'
                }
              }}
            />
          )}
        </ModalContainer>
      </Modal>
    </InfoContainer>
  )
}

export default EarningsByProfessional
