import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

export const StepColumn = styled.div`
  width: 480px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  background-color: #F7F7F7;
  box-shadow: 0px 2px 10px -6px #252525;
  padding: 15px 20px;
  border-radius: 15px;
`

export const StepLabel = styled.p`
  color: #BBBBBB;
  font-size: 14px;
  margin: 0px;
`

export const StepCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ step, currentStep }) => (
    currentStep.number > step.number
      ? '#44CF6C'
      : currentStep.number === step.number ? '#FFC100' : '#252525'
  )};

  p {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
  }
`

export const Title = styled.h2`
  color: ${({ isComplete }) => isComplete ? '#44CF6C': '#FFC100'};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`

export const InfoText = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 0px;
  text-align: center;
`

export const LinkText = styled(Link)`
  color: #35A7FF;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #2875E2;
  }
`

export const Button = styled.button`
  width: 140px;
  align-self: center;
  background-color: #252525;
  color: #FFFFFF;
  padding: 8px 20px;
  margin: 10px 0px 20px;
  border: 0px;
  border-radius: 15px;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: #121212;
    color: #FFFFFF;
  }
`
