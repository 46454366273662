import React, { useState } from 'react'
import cutileiApi from '../../services/cutileiApi'
import AuthService from '../../services/auth'
import { ReactComponent as ButtonLoading } from '../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  Container,
  StepColumn,
  StepContainer,
  StepCircle,
  StepLabel,
  Title,
  InfoText,
  LinkText,
  Button
} from './styles'

function Tutorial ({
  businessConfig,
  onCompleted: handleCompleted
}) {
  const {
    isAgendaConfig, 
    isServicesConfig,
    isProfessionalAdded,
    isProfessionalAgendaConfig,
    isProfessionalServiceConfig,
    isRequiredStepsCompleted,
    isTutorialFinished,
    isBundlesConfig,
    isProductsConfig,
    addedProfessional
  } = businessConfig

  const [loading, setLoading] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const configSteps = [
    {
      number: 1,
      visible: true,
      label: 'Configurar agenda do salão',
      link: {
        pathname: '/business/config',
        state: { configOption: 'agenda', isTutorial: true }
      },
      isCompleted: isAgendaConfig,
      optional: false
    },
    {
      number: 2,
      visible: true,
      label: 'Configurar serviços do salão',
      link: {
        pathname: '/business/config',
        state: { configOption: 'services', isTutorial: true }
      },
      isCompleted: isServicesConfig,
      optional: false
    },
    {
      number: 3,
      visible: true,
      label: 'Cadastrar um(a) profissional',
      link: {
        pathname: '/business/add-professional',
        state: { isTutorial: true }
      },
      isCompleted: isProfessionalAdded,
      optional: false
    },
    {
      number: 4,
      visible: true,
      label: 'Configurar agenda do(a) profissional',
      link: {
        pathname: `/business/professionals/${addedProfessional?.id}/edit`,
        state: { configOption: 'agenda', isTutorial: true }
      },
      isCompleted: isProfessionalAgendaConfig,
      optional: false
    },
    {
      number: 5,
      visible: true,
      label: 'Configurar serviços do(a) profissional',
      link: {
        pathname: `/business/professionals/${addedProfessional?.id}/edit`,
        state: { configOption: 'services', isTutorial: true }
      },
      isCompleted: isProfessionalServiceConfig,
      optional: false
    },
    {
      number: 6,
      visible: true,
      label: 'Configurar pacotes do salão',
      link: {
        pathname: '/business/config',
        state: { configOption: 'bundles', isTutorial: true }
      },
      isCompleted: isBundlesConfig,
      optional: true
    },
    {
      number: 7,
      visible: true,
      label: 'Configurar produtos do salão',
      link: {
        pathname: '/business/config',
        state: { configOption: 'products', isTutorial: true }
      },
      isCompleted: isProductsConfig,
      optional: true
    },
    {
      number: 8,
      visible: false,
      isCompleted: isTutorialFinished,
      optional: false
    }
  ]

  const currentStep = configSteps [configSteps.findIndex (step => !step.isCompleted)]

  const isPreviousStepsCompleted = (currentStep) => (
    !configSteps.some (step => step.number < currentStep.number && !step.isCompleted)
  )

  const finishTutorial = async () => {
    try {
      setLoading (true)

      await cutileiApi.post (
        `/businesses/${businessId}/validate_config`,
        {config_completed: true},
        requestConfig
      )

      setLoading (false)
      handleCompleted ()
    } catch (error) {
      setLoading (false)
      console.log (error)
    }
  }

  return (
    <Container>
      {!isRequiredStepsCompleted ? (
        <>
          <Title isComplete={isRequiredStepsCompleted}>
            Como configurar seu salão
          </Title>
          <InfoText>
            Siga os passos numerados abaixos para deixar seu salão configurado e pronto para começar a atender.
          </InfoText>
          <InfoText style={{marginBottom: 20}}>
            Os passos opcionais não são necessários para concluir a configuração.
          </InfoText>
        </>
      ) : (
        <>
          <Title isComplete={isRequiredStepsCompleted}>
            Parabéns, você concluiu a configuração do seu salão!
          </Title>
          <InfoText style={{width: 480}}>
            Agora você já pode começar a marcar agendamentos e já está visível para os clientes da Cutilei!
            Basta clicar no botão abaixo para ver sua agenda.
          </InfoText>
          <InfoText style={{marginTop: 7}}>
            Para alterar as suas configurações, acesse a página de
            <LinkText to='/business/config'> 
              {' Configurações '}
              <FA.FaCog size={16} color='#000000' style={{marginBottom: 2}}/>
            </LinkText>
          </InfoText>
          <Button onClick={finishTutorial}>
            {loading ? <ButtonLoading/> : 'Ver agenda'}
          </Button>
        </>
      )}
      <StepColumn>
        {configSteps.map ((step, index) => step.visible && (
          <StepContainer key={index}>
            <StepCircle step={step} currentStep={currentStep}>
              {step.isCompleted ? (
                <FA.FaCheck color='#FFFFFF' size={16}/>
              ) : (
                <InfoText>{step.number}</InfoText>
              )}
            </StepCircle>
            {(isPreviousStepsCompleted (step) && !step.isCompleted) || step.optional ? (
              <LinkText to={step.link}> 
                {step.label}
              </LinkText>
            ) : (
              <InfoText>{step.label}</InfoText>
            )}
            {step.optional && <StepLabel>{' (opcional)'}</StepLabel>}
          </StepContainer>
        ))}
      </StepColumn>
    </Container>
  )
}

export default Tutorial
