import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'

const cellWidth = 60
const cellHeight = 26

function getNumConflictingSchedules (schedules, timeWindows) {
  let numSchedulesPerTime = []

  // Função para converter string de hora para objeto DateTime
  const parseTime = timeString => DateTime.fromFormat (timeString, 'H:mm')

  // Verificar agendamentos no mesmo período de tempo
  timeWindows.forEach (timeWindow => {
    const parsedTimeWindow = parseTime (timeWindow)

    const schedulesPerTime = schedules.filter (schedule => {
      const scheduleStart = schedule.start_time
        ? parseTime (schedule.start_time) 
        : parseTime (schedule.time)

      const scheduleEnd = schedule.end_time
        ? parseTime (schedule.end_time) 
        : parseTime (schedule.time).plus ({minutes: schedule.duration})

      return parsedTimeWindow >= scheduleStart && parsedTimeWindow < scheduleEnd
    })

    numSchedulesPerTime.push (schedulesPerTime.length)
  })

  return Math.max (...numSchedulesPerTime)
}

export const Container = styled.div`
  width: calc(100vw - 420px);
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`

export const ProfessionalsRow = styled.div`
  width: auto;
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: repeat(${({ numColumns }) => numColumns}, 1fr);
  justify-items: center;
  position: sticky;
  top: 15px;
  z-index: 3;
  margin-bottom: 3px;
  margin-left: 50px;
  gap: 5px;
`

export const ScheduleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ numColumns }) => numColumns}, minmax(${cellWidth + 'px'} , 1fr));
  background-color: transparent;
  justify-content: center;
  align-items: flex-start;
  padding-left: 50px;
  column-gap: 5px;
`

export const ProfessionalColumn = styled.div`
  display: grid;
  grid-template-columns: ${({ schedules, timeWindows }) => {
    const numConflictingSchedules = getNumConflictingSchedules (schedules, timeWindows)
    return `repeat(${numConflictingSchedules}, calc(100% / ${numConflictingSchedules}))`
  }};
  grid-template-rows: repeat(${({ numRows }) => numRows} , ${cellHeight + 'px'});
  background-color: transparent;
  padding: 0px;
`

export const TimeGrid = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: repeat(${({ numRows }) => numRows} , ${cellHeight + 'px'});
  grid-template-columns: ${({ numColumns }) => ((numColumns + 1) * cellWidth + 46) + 'px'};
  margin: 0px -8px -8px -60px;
  border-radius: 15px;
`

export const LinkGrid = styled(ScheduleGrid)`
  width: calc(100vw - 470px);
  position: absolute;
  grid-template-rows: repeat(${({ numRows }) => numRows} , ${cellHeight + 'px'});
  grid-template-columns: repeat(${({ numColumns }) => numColumns}, minmax(${cellWidth + 'px'} , 1fr));
  padding: 0px;
`

export const TimeMark = styled.div`
  display: block;
  background-color: transparent;
  width: calc(100vw - 407px);
  border-top: 1px solid #CCCCCC;

  &:hover {
    border-top: 2px solid #AAAAAA;
  }

  &:before {
    content: '${({ label }) => label}';
    color: #000000;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    left: 0px;
    margin-top: 1px;
    margin-left: 5px;
  }
`

export const InfoText = styled.p`
  color: #000000;
  font-size: 14px;
  text-align: center;
  margin-bottom: 5px;
`

export const LinkText = styled(Link)`
  color: #35A7FF;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #2875E2;
  }
`
