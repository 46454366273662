import React, { useState } from 'react'
import * as IO from 'react-icons/io5'

import {
  Container,
  Input,
  EyeToggle
} from './styles'

function PasswordInput ({
  placeholder,
  value,
  onChange: handleChange,
  onBlur: handleBlur,
  containerStyle = {},
  inputStyle = {}
}) {
  const [hidePass, setHidePass] = useState (true)

  const toggleHidePass = () => setHidePass (!hidePass)

  return (
    <Container style={containerStyle}>
      <Input
        type={hidePass ? 'password' : 'text'}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        style={inputStyle}
        autoComplete='off'
      />
      <EyeToggle onClick={toggleHidePass}>
        {hidePass ? (
          <IO.IoEye color='#252525' size={18}/>
        ) : (
          <IO.IoEyeOff color='#252525' size={18}/>
        )}
      </EyeToggle>
    </Container>
  )
}

export default PasswordInput
