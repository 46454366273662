import React, { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { apiUrl } from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import Logo from '../../../icons/logo-name.png'
import io from 'socket.io-client'

import {
  modalStyle,
  Container,
  Header,
  Row,
  Image,
  Title,
  InfoText,
  Button,
  ButtonText
} from './styles'

function InactiveBusinessModal ({
  visible,
  onClose: handleClose
}) {
  const [showCloseButton, setShowCloseButton] = useState (false)
  const businessId = AuthService.getBusinessId ()

  useEffect (() => {
    const newSocket = io (apiUrl)

    newSocket.on (`${businessId}_business_activated`, () => setShowCloseButton (true))

    return () => {newSocket.disconnect ()}
  }, [])

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container>
        <Image src={Logo} alt='Cutilei'/>
        {showCloseButton ? (
          <>
            <Header>
              <Title>Seu salão foi ativado!</Title>
            </Header>
            <InfoText style={{width: 360}}>
              Agora, prossiga para as etapas de configuração para começar a atender.
            </InfoText>
            <Row>
              <Button onClick={handleClose}>
                <ButtonText>
                  Prosseguir
                </ButtonText>
              </Button>
            </Row>
          </>
        ) : (
          <>
            <Header>
              <Title>Você está quase lá!</Title>
            </Header>
            <InfoText style={{marginBottom: 10}}>
              Estamos verificando seu cadastro.
            </InfoText>
            <InfoText>
              Em instantes, sua área logada será liberada para que você possa prosseguir com as configurações de seu salão.
            </InfoText>
          </>
        )}
      </Container>
    </Modal>
  )
}

export default InactiveBusinessModal
