import React, { useState } from 'react'
import Modal from 'react-modal'
import cutileiApi from '../../../services/cutileiApi'
import AuthService from '../../../services/auth'
import { DateTime } from 'luxon'
import { ReactComponent as ButtonLoading } from '../../../icons/loading.svg'
import * as FA from 'react-icons/fa'

import {
  modalStyle,
  Container,
  Header,
  Row,
  Title,
  InfoText,
  Button,
  DangerButton,
  CloseButton,
  ButtonText
} from './styles'

function RemoveProfessionalDialog ({
  visible,
  title = 'Remover profissional da agenda',
  professional,
  notifyAbsence,
  onConfirm: handleConfirm,
  onClose: handleClose
}) {
  const [removing, setRemoving] = useState (false)
  const businessId = AuthService.getBusinessId ()
  const token = AuthService.getToken ()
  const firstName = professional.name.split(' ')[0]

  const requestConfig = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }

  const removeProfessional = async () => {
    setRemoving (true)
    try {
      const currentDate = DateTime.now ().toISODate ()
      const endDate = DateTime.now ().plus ({years: 100}).toISODate ()

      await cutileiApi.put (
        `/businesses/${businessId}/professionals/${professional.id}/activation`,
        {active: false},
        requestConfig
      )
      await notifyAbsence (currentDate, endDate)

      setRemoving (false)
      handleConfirm (professional)
      handleClose ()
    } catch (error) {
      setRemoving (true)
      console.log (error)
    }
  }

  return (
    <Modal
      isOpen={visible}
      onRequestClose={handleClose}
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      style={modalStyle}
    >
      <Container>
        <Header>
          <Title>
            {title}
          </Title>
          <CloseButton onClick={handleClose}>
            <FA.FaTimes color='#FF3939' size={18}/>
          </CloseButton>
        </Header>
        <InfoText style={{marginBottom: 10}}>
          Ao remover este profissional da agenda, ele também não aparecerá para os membros da Cutilei. 
          Tentaremos realocar novos profissionais para seus agendamentos e avisaremos os clientes.
        </InfoText>
        <InfoText>
          Caso não haja profissional disponível para substituir {firstName} na data e horário do agendamento, avisaremos ao cliente para que o altere.
        </InfoText>
        <InfoText>
          Você poderá reativar o profissional a qualquer momento na aba de profissionais, em "configurações".
        </InfoText>
        <Row>
          <DangerButton onClick={removeProfessional}>
            {removing ? (
              <ButtonLoading/>
            ) : (
              <ButtonText>
                Confirmar
              </ButtonText>
            )}
          </DangerButton>
          <Button onClick={handleClose}>
            <ButtonText>
              Cancelar
            </ButtonText>
          </Button>
        </Row>
      </Container>
    </Modal>
  )
}

export default RemoveProfessionalDialog
